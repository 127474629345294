import { libraryServices } from '@/modules/library/library-service';
// import { firestoreAction } from 'vuexfire';
// import firebase from 'firebase/app';
// import productListExporterFields from '@/modules/product/product-list-exporter-fields';
import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';
const INITIAL_PAGE_SIZE = 10;
export default {
  namespaced: true,
  state: {
    rows: [],
    record: null,
    count: 0,
    loading: true,
    filter: {},
    pagination: {},
    sorter: {},
    adminstration: [],
    currentUserPrescriptions: [],
    prescriptionsWithCreators: [],
    user: {},
    mountedTable: false,
    currentRoute: undefined,
    table: null,
  },

  getters: {
    loading: (state) => state.loading,
    record: (state) => state.record,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    adminstration: (state) => {
      return state.adminstration.sort((a, b) => {
        var keyA = a.date,
          keyB = b.date;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    mountedTable: (state) => state.mountedTable,
    currentRoute: (state) => state.currentRoute,
    user: (state) => state.user,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FIND_SUCCESS(state, payload) {
      state.loading = false;
      state.record = payload;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },

    APPEND_TO_LIST(state, payload) {
      state.rows.push(payload);
    },
  },

  actions: {
    setMountedTable({ commit }, value) {
      commit('SET_MOUNTEDTABLE', value);
    },
    setCurrentRoute({ commit }, value) {
      commit('SET_CURRENTROUTE', value);
    },

    async doFetch({ commit }, branchId) {
      try {
        commit('FETCH_STARTED');
        const response = await libraryServices.list(
          branchId,
        );
        console.log(response);
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    async doFind({ commit }, id) {
      try {
        commit('FETCH_STARTED');
        console.log('ID IS ' + id);
        const response = await libraryServices.find(id);
        console.log(response);
        commit('FIND_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    // doFind: firestoreAction(
    //   async ({ bindFirestoreRef, commit }, payload) => {
    //     commit('FETCH_STARTED');
    //     console.log('in do find', payload.id);
    //     // debugger
    //     const db = firebase.firestore();
    //     const recordDocument = db
    //       .collection('library')
    //       .doc(payload.id);
    //     await bindFirestoreRef('record', recordDocument);
    //     // console.log('getters record', getters.record);
    //   },
    // ),
  },
};
