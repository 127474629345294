import { render, staticRenderFns } from "./drop-down-menu.vue?vue&type=template&id=2647def5&scoped=true&"
import script from "./drop-down-menu.vue?vue&type=script&lang=js&"
export * from "./drop-down-menu.vue?vue&type=script&lang=js&"
import style0 from "./drop-down-menu.vue?vue&type=style&index=0&id=2647def5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2647def5",
  null
  
)

export default component.exports
import {ClosePopup} from 'quasar'
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
