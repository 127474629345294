const Layout = () =>
  import('@/modules/layout/components/layout.vue');
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const BranchListPage = () =>
  import(
    '@/modules/branch/components/branch-list-page.vue'
  );
const BranchViewPage = () =>
  import(
    '@/modules/branch/components/branch-view-page.vue'
  );

export default [
  {
    // path: '',
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'branch',
        path: 'branch',
        component: BranchListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.branches');
          next();
        },
      },
      {
        name: 'branch-view',
        path: 'branch/:id',
        component: BranchViewPage,
      },
    ],
  },
];
