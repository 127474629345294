import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const LiveListPage = () =>
  import('@/modules/live/components/live-list-page.vue');

export default [
  {
    // path: '',
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'live',
        path: 'live',
        component: LiveListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') + ' | ' + i18n('menu.live');
          next();
        },
      },
    ],
  },
];

// export default [
//   {
//     path: '/:lang?',
//     exact: true,
//     component: Layout,
//     children: [
//       {
//         name: 'media',
//         path: 'media',
//         component: MediaViewPage,
//         // exact: true,
//         meta: { auth: false },
//         beforeEnter: (to, from, next) => {
//           document.title =
//             i18n('app.title') + ' | ' + i18n('menu.media');
//           next();
//         },
//       },

//     ],
//   },
// ];
