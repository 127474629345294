
import { mapGetters, mapActions } from 'vuex';
import service from '@/modules/auth/auth-service';
import browserUpdate from 'browser-update';
import AOS from 'aos';
import firebase from 'firebase/app';

import 'firebase/auth'; // for authentication
import i18n from '@/vueI18n';

browserUpdate({
  required: { e: 0, f: 0, o: 0, s: 0, c: 0 },
  insecure: true,
  api: 2020.12,
});

// var $buoop = {required:{e:0,f:0,o:0,s:0,c:0},insecure:true,api:2020.12 }
// function $buo_f() {
//   var e = document.createElement("script")
//   e.src = "//browser-update.org/update.min.js"
//   document.body.appendChild(e)
// };

export default {
  name: 'app',
  data() {
    return {
      browser: {},
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hover: false,
      hideCursor: true,
    };
  },

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },

  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises);
    // await firebase.auth().signInAnonymously();
    // console.log("this is Anonymous Token", await firebase
    //   .auth()
    //   .currentUser.getIdToken(true))

    // await firebase
    //   .auth()
    //   .signInWithEmailAndPassword(
    //     'mohamedali.runprof@gmail.com',
    //     '123456',
    //   )
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        window.localStorage.getItem('userId')
          ? ``
          : window.localStorage.setItem('userId', user.uid);
        // ...
      }
    });

    AOS.init();
    // AOS.init({
    //   once: false,
    // })
    const browser = service.get_browser();
    console.log('browser', browser);
    this.browser = browser;
    localStorage.setItem(
      'browser',
      `${browser.name} ${browser.version}`,
    );

    // try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
    // catch(e){window.attachEvent("onload", $buo_f)}

    //old signin from ghada >>>>>>>

    // await firebase.auth().signInAnonymously();

    // if (!this.anonymousToken) {
    //   const firebaseUser = await firebase
    //     .auth()
    //     .signInAnonymously();
    //   const token = firebaseUser.user.uid;
    //   console.log('Token: ', token);
    //   this.setAnonymousToken(token);
    // }

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    //   firebase.auth().signInAnonymously()
    // .then((firebaseUser) => {
    //   console.log('firebaseUser',firebaseUser)
    //   this.token = firebaseUser.user.uid
    //   console.log('id',this.token)
    //     this.setToken(this.token)
    //   // Signed in..
    // })
    // .catch((error) => {
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   console.log('errorCode',errorCode)
    //   console.log('errorMessage',errorMessage)
    //   // ...
    // });
  },
  mounted() {
    //  console.log('mounted')

    // Storing Dark mode in localStorage

    if (localStorage.getItem('mode')) {
      this.$q.dark.set(localStorage.getItem('mode'));
    } else {
      const hours = new Date().getHours();
      hours >= 12
        ? this.$q.dark.set(true)
        : this.$q.dark.set(false);
    }

    // const hours = (new Date()).getHours()
    //   return hours >= 12 ? true : false

    document.addEventListener('mousemove', this.moveCursor);
    document.addEventListener('mouseleave', () => {
      // console.log('mouse leave')

      this.hideCursor = true;
    });
    document.addEventListener('mouseenter', () => {
      // console.log('mouse enter')
      this.hideCursor = false;
    });
    if (localStorage.getItem('language') == 'ar') {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
      setAnonymousToken: 'layout/setAnonymousToken',
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    // async addToCache(urls) {
    //
    //   const myCache = await window.caches.open('my-cache');
    //   await myCache.addAll(urls);
    // },
    moveCursor(e) {
      // console.log('e',e);
      this.xChild = e.clientX;
      this.yChild = e.clientY;
      setTimeout(() => {
        this.xParent = e.clientX - 15;
        this.yParent = e.clientY - 15;
      }, 100);
    },
  },

  watch: {
    'i18n.locale'(newVal) {
      console.log(newVal);
      //
    },
    lang(newVal, oldVal) {
      // debugger
      console.log('lang old value', oldVal);
      console.log('lang new value', newVal);

      if (newVal == 'ar') {
        document
          .getElementsByTagName('html')[0]
          .setAttribute('dir', 'rtl');
        this.$q.lang.rtl = true;
      }
      if (newVal == 'en') {
        document
          .getElementsByTagName('html')[0]
          .setAttribute('dir', 'ltr');
        this.$q.lang.rtl = false;
      }
    },
  },
  computed: {
    isDark() {
      return this.$q.dark.isActive;
    },
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      anonymousToken: 'layout/anonymousToken',
    }),
    lang() {
      return i18n.locale;
    },
    cursorCircle() {
      //  console.log('cursorCircle',this.xParent)
      //  console.log('this.yParent',this.yParent)
      return `transform: translateX(${this.xParent}px) translateY(${this.yParent}px) translateZ(0) translate3d(0, 0, 0)!important;`;
    },
    cursorPoint() {
      // console.log('this.xChild',this.yChild)
      return `transform: translateX(${
        this.xChild - 3
      }px) translateY(${
        this.yChild - 3
      }px) translateZ(0) translate3d(0, 0, 0)!important;`;
    },
  },
};
