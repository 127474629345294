
// import { i18n } from '@/i18n';

export default {
  name: 'app-i18n',
  props: ['code', 'args'],
  computed: {
    message: function() {
      // return i18n(this.code, ...(this.args || []));
      return this.$t(this.code, ...(this.args || []));
    },
  },
};
