import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const ResurantDiscountsListPage = () =>
  import(
    '@/modules/restaurants-discounts/components/restaurants-discounts-list-page.vue'
  );

const resturantDiscountViewPage = () =>
  import(
    '@/modules/restaurants-discounts/components/restaurants-discounts-view.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'restaurants-discounts',
        path: 'restaurants-discounts',
        component: ResurantDiscountsListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.restaurants-discounts');
          next();
        },
      },
      {
        name: 'restaurants-discounts-view',
        path: 'restaurants-discounts/:id',
        component: resturantDiscountViewPage,
      },
    ],
  },
];
