import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-sponsor';
import graphqlClientAds from '@/shared/graphql/client-ads';
import graphqlClientForm from '@/shared/graphql/client-form';

import graphqlClientSocials from '@/shared/graphql/client-socials';
import { type } from 'jquery';

export class SponsorService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query SPONSOR_LIST(
          $filter: SponsorFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          sponsorList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              link
              logo

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 100,
          sortBy: 'asc',
        },
      },
    });
    return response.data.sponsorList;
  }

  static async listAds(type) {
    const response = await graphqlClientAds.query({
      query: gql`
        query ADS_LIST(
          $filter: AdsFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          adsList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              image
              link
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              endDate
              appearIn
              type
              position
              views

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: {
          type,
          appearIn: ['web', 'both'],
        },
        orderBy: 'createdAt',
        pagination: {
          limit: 50,
          sortBy: 'asc',
        },
      },
    });
    return response.data.adsList;
  }

  static async listSocialMedia() {
    const response = await graphqlClientSocials.query({
      query: gql`
        query SOCIAL_MEDIA_LIST(
          $filter: SocialMediaFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          socialMediaList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              link
              logo
              status

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { status: 'enabled' },
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.socialMediaList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query AUCTION_FIND($id: String!) {
          auctionFind(id: $id) {
            id
            banner
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            termsAndConditions {
              en
              ar
            }
            postDate
            expiryDate
            reservePrice
            TCTerms
            status
            views
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.auctionFind;
  }

  static async updateViews(id, ip) {
    const response = await graphqlClientAds.query({
      query: gql`
        mutation UPDATE_VIEWS_COUNT(
          $id: String!
          $ip: String!
        ) {
          adsUpdateViewsCount(id: $id, ip: $ip)
        }
      `,
      variables: {
        id: id,
        ip: ip,
      },
    });
    return response.data.adsUpdateViewsCount;
  }

  static async createContact(data) {
    const response = await graphqlClientForm.query({
      query: gql`
        mutation CREATE($data: FormsInput!) {
          formsCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: data,
      },
    });
    return response.data.sponsorList;
  }
}
