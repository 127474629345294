// import { NewsService } from '@/modules/news/news-service';
// import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import { firestorePlugin } from 'vuefire';
import Vue from 'vue';
import { NewsService } from '@/modules/news/news-service';
// import productListExporterFields from '@/modules/product/product-list-exporter-fields';
import Errors from '@/shared/error/errors';
Vue.use(firestorePlugin);

export default {
  namespaced: true,

  state: {
    viewsCounter: null,
    loading: false,
    userLoading: false,
    record: null,
    user: null,
    likes: [],
    isLiked: null,
    viewer: null,
  },

  getters: {
    viewsCounter: (state) => state.viewsCounter,
    record: (state) => state.record,
    user: (state) => state.user,
    loading: (state) => !!state.loading,
    likes: (state) => state.likes,
    isLiked: (state) => state.isLiked,
    viewer: (state) => state.viewer,
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    UPDATE_VIEWS(state, payload) {
      state.viewsCounter = payload;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ARTICLE_DETAILS_STARTED(state) {
      state.loading = true;
    },

    FIND_ARTICLE_DETAILS_SUCCESS(state) {
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_USER_STARTED(state) {
      state.user = null;
      state.userLoading = true;
    },

    FIND_USER_SUCCESS(state, payload) {
      state.user = payload;
      console.log('state user', state.user);
      state.userLoading = false;
    },

    FIND_USER_ERROR(state) {
      state.user = null;
      state.userLoading = false;
    },
  },

  actions: {
    //

    async doFind(
      { commit },
      id, //   currentUserId
    ) {
      try {
        // console.log('in do fetch',currentUserId)
        commit('FIND_STARTED');
        const response = await NewsService.find(id);
        console.log('hh', response);
        commit('FIND_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
    async doUpdateViews({ commit }, { id, ip }) {
      try {
        console.log('ID IS ' + id);
        const response = await NewsService.updateViews(
          id,
          ip,
        );
        commit('UPDATE_VIEWS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    async doUpdateLikes({ commit }, { id, ip }) {
      try {
        await NewsService.updateLikes(id, ip);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    async doUnlike({ commit }, { id, ip }) {
      try {
        NewsService.unlike(id, ip);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
    // async doFindUser({ commit },userId) {
    //   try {
    //     console.log('in doFind User view store')
    //     commit('FIND_USER_STARTED');
    //     await PrescriptionsService.findUser(userId, async(doc) => {
    //       console.log('createdBy',doc)
    //       commit('FIND_USER_SUCCESS',  doc);
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_USER_ERROR');
    //     // routerAsync().push('/offer');
    //   }
    // },

    doFetchLikes: firestoreAction(
      async ({ bindFirestoreRef, commit }, id) => {
        commit('FIND_ARTICLE_DETAILS_STARTED');
        const db = firebase.firestore();
        const likes = db.collection(`news/${id}/likes`);

        // }
        await bindFirestoreRef('likes', likes);
        commit('FIND_ARTICLE_DETAILS_SUCCESS');
      },
    ),
    doFetchUserLike: firestoreAction(
      async ({ bindFirestoreRef, commit }, payload) => {
        commit('FIND_ARTICLE_DETAILS_STARTED');
        const db = firebase.firestore();
        // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
        const isLiked = db
          .collection(`news/${payload.articleId}/likes`)
          .doc(payload.userId);

        // }
        await bindFirestoreRef('isLiked', isLiked);
        commit('FIND_ARTICLE_DETAILS_SUCCESS');
      },
    ),

    doLike: firestoreAction(
      async (
        { bindFirestoreRef, commit, getters },
        payload,
      ) => {
        commit('FIND_ARTICLE_DETAILS_STARTED');
        const db = firebase.firestore();
        const isLiked = db
          .collection(`news/${payload.articleId}/likes`)
          .doc(payload.userId);

        // }
        await bindFirestoreRef('isLiked', isLiked);
        if (getters.isLiked === null) {
          await db
            .collection(`news/${payload.articleId}/likes`)
            .doc(payload.userId)
            .set({ id: payload.userId });
          //   await firebase.firestore()
          //   .collection(`news/`)
          //   .doc(payload.articleId)
          //   .update({
          //     likesCount: firebase.firestore.FieldValue.increment(1)
          // })
        } else {
          await db
            .collection(`news/${payload.articleId}/likes`)
            .doc(payload.userId)
            .delete();

          // await firebase.firestore()
          //   .collection(`news/`)
          //   .doc(payload.articleId)
          //   .update({
          //     likesCount: firebase.firestore.FieldValue.increment(-1)
          // })
        }
      },
    ),

    doFetchViewers: firestoreAction(
      async (
        { bindFirestoreRef, commit, getters },
        payload,
      ) => {
        commit('FIND_ARTICLE_DETAILS_STARTED');
        const db = firebase.firestore();
        // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
        const isAViewer = db
          .collection(`news/${payload.articleId}/views`)
          .doc(payload.userId);

        // }
        await bindFirestoreRef('viewer', isAViewer);
        if (getters.viewer === null) {
          await db
            .collection(`news/${payload.articleId}/views`)
            .doc(payload.userId)
            .set({ id: payload.userId });

          // await firebase.firestore()
          // .collection(`news/`)
          // .doc(payload.articleId)
          //   .update({
          //     viewersCount: firebase.firestore.FieldValue.increment(1)
          // })
        }
        commit('FIND_ARTICLE_DETAILS_SUCCESS');
      },
    ),
  },
};
