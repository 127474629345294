import firebase from 'firebase/app';
import 'firebase/auth';

export class AuthToken {
  static async get() {
    await firebase.auth().signInAnonymously();
    // await firebase
    //   .auth()
    //   .signInWithEmailAndPassword(
    //     'mohamedali.runprof@gmail.com',
    //     '123456',
    //   );
    // await firebase
    //   .auth()
    //   .signInWithEmailAndPassword(
    //     'mohamedali.runprof@gmail.com',
    //     '123456',
    //   );

    if (
      firebase.apps.length &&
      firebase.auth().currentUser
    ) {
      return await firebase
        .auth()
        .currentUser.getIdToken(true);
    }
  }

  static getUserId() {
    if (
      firebase.apps.length &&
      firebase.auth().currentUser
    ) {
      return firebase.auth().currentUser.uid;
    }

    return null;
  }
}
