import { render, staticRenderFns } from "./shop-card.vue?vue&type=template&id=1b38f16a&scoped=true&"
import script from "./shop-card.vue?vue&type=script&lang=js&"
export * from "./shop-card.vue?vue&type=script&lang=js&"
import style0 from "./shop-card.vue?vue&type=style&index=0&id=1b38f16a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b38f16a",
  null
  
)

export default component.exports
import {QCard,QBtn,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QBtn,QImg})
