import firebase from 'firebase/app';
import 'firebase/firestore';
import { getLanguageCode } from '@/i18n';
import { SponsorService } from './layout-service';
import {
  firestoreAction,
  vuexfireMutations,
} from 'vuexfire';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,

  state: {
    currentLanguageCode: getLanguageCode(),
    menuCollapsed: false,
    rows: [],
    socials: [],
    ads: [],
    loading: null,
    isMobile: false,
    is_screen_xs: false,
    is_screen_sm: false,
    is_screen_md: false,
    is_screen_lg: false,
    is_labtop: false,
    is_screen_ipad_pro: false,
    anonymousToken: null,
    sports: [],
    loadingSports: false,
  },

  getters: {
    currentLanguageCode: (state) =>
      state.currentLanguageCode,
    rows: (state) => state.rows,
    socials: (state) => state.socials,
    ads: (state) => state.ads,
    loading: (state) => state.loading,
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    is_screen_xs: (state) => !!state.is_screen_xs,
    is_screen_sm: (state) => !!state.is_screen_sm,
    is_screen_md: (state) => !!state.is_screen_md,
    is_screen_lg: (state) => !!state.is_screen_lg,
    is_labtop: (state) => !!state.is_labtop,
    is_screen_ipad_pro: (state) =>
      !!state.is_screen_ipad_pro,

    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
    anonymousToken: (state) => state.anonymousToken,
    sports: (state) => state.sports,
    loadingSports: (state) => !!state.loadingSports,
  },

  mutations: {
    SET_CURRENT_LANGUAGE_CODE(state, payload) {
      state.currentLanguageCode = payload;
    },

    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
      state.is_screen_xs = payload.width < 600;
      state.is_screen_sm =
        payload.width >= 600 && payload.width < 1024;
      state.is_screen_md =
        payload.width >= 1024 && payload.width < 1440;
      state.is_screen_lg = payload.width >= 1024;
      state.is_labtop =
        payload.width <= 1400 && payload.height <= 800;
      state.is_screen_ipad_pro = payload.width == 1024;
    },
    SET_ANONYMOUS_TOKEN(state, payload) {
      state.anonymousToken = payload;
    },
    ...vuexfireMutations,

    FETCH_SPORTS_STARTED(state) {
      state.loadingSports = true;
    },
    FETCH_SPORTS_SUCCESS(state) {
      state.loadingSports = false;
    },

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.rows = payload.rows;
    },
    FETCH_ERROR(state) {
      state.loading = false;
    },

    FETCH_SOCIALS_STARTED(state) {
      state.loading = true;
    },
    FETCH_SOCIALS_SUCCESS(state, payload) {
      state.socials = payload.rows;
    },
    FETCH_ADS_STARTED(state) {
      state.loading = true;
    },
    FETCH_ADS_SUCCESS(state, payload) {
      state.ads = payload.rows;
    },
  },

  actions: {
    productListener({ commit }) {
      console.log(commit);
      firebase
        .firestore()
        .collection('items')
        .onSnapshot((snapshot) => {
          let arr = snapshot
            .docChanges()
            .map((I) => I.doc.data());
          let allDocs = snapshot.docs.map((I) => I.data());
          arr.forEach(async (item) => {
            try {
              const quantity = allDocs.filter(
                (I) =>
                  I.itemId === item.itemId &&
                  I.status === 'inStock',
              ).length;
              await firebase
                .firestore()
                .collection(item.itemType)
                .doc(item.itemId)
                .update({ quantity: quantity });
            } catch (error) {
              console.log(error);
            }
          });
        });
    },

    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    changeCurrentLanguageCode({ commit }, language) {
      commit('SET_CURRENT_LANGUAGE_CODE', language);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },
    setAnonymousToken({ commit }, payload) {
      commit('SET_ANONYMOUS_TOKEN', payload);
    },
    // , dispatch
    doFetchSports: firestoreAction(
      async ({ bindFirestoreRef, getters, commit }) => {
        commit('FETCH_SPORTS_STARTED');
        const db = firebase.firestore();
        const sportsCollection = db
          .collection(
            'contentType/7z9dYgkUCW6fAFl2UN5q/category',
          )
          .orderBy('index', 'asc');
        await bindFirestoreRef('sports', sportsCollection);
        getters.sports.forEach(async (sport) => {
          let subCategory = await db
            .collection(
              `contentType/7z9dYgkUCW6fAFl2UN5q/category/${sport.id}/subCategories`,
            )
            .get();
          if (subCategory.docs.length > 0) {
            sport['subCategories'] = [];
            subCategory.docs.forEach((doc) => {
              sport['subCategories'].push(doc.data());
            });
          }
        });
        commit('FETCH_SPORTS_SUCCESS');
      },
    ),

    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED');

        const response = await SponsorService.list();
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchSocials({ commit }) {
      try {
        commit('FETCH_SOCIALS_STARTED');

        const response =
          await SponsorService.listSocialMedia();
        commit('FETCH_SOCIALS_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doCreateContact({ commit }, data) {
      try {
        await SponsorService.createContact(data);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchAds({ commit }, type) {
      try {
        commit('FETCH_ADS_STARTED');

        const response = await SponsorService.listAds(type);
        commit('FETCH_ADS_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doUpdateViews({ commit }, { id, ip }) {
      try {
        commit('FETCH_ADS_STARTED');

        const response = await SponsorService.updateViews(
          id,
          ip,
        );
        console.log(
          'this is sponsor views return ',
          response,
        );
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};
