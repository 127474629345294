import Layout from '@/modules/layout/components/layout.vue';
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const AuctionListPage = () =>
  import('@/modules/auction/components/auction-list-page.vue');
const AuctionsViewPage = () =>
  import('@/modules/auction/components/auction-view-page.vue');

export default [{
  path: '/:lang?',
  exact: true,
  component: Layout,
  children: [
    {
      name: 'auctions',
      path: 'auctions',
      component: AuctionListPage,
      meta: { auth: false },
      beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('menu.auctions') 
        next();
      },
    },
    {
      name: 'auctions-view',
      path: 'auctions/:id',
      component: AuctionsViewPage,
    },
    // {
    //   name: 'first-team',
    //   path: '/first-team',
    //   component: FirstTeamTest,
    // },

  ]
}];