// import prescriptionsFormStore from '@/modules/prescriptions/prescriptions-form-store';
// import prescriptionsDestroyStore from '@/modules/prescriptions/prescriptions-destroy-store';
import articleListStore from '@/modules/article/article-list-store';
import articleViewStore from '@/modules/article/article-view-store';

export default {
    namespaced: true,
  
    modules: {
    //   form: prescriptionsFormStore,
    //   destroy: prescriptionsDestroyStore,
      list: articleListStore,
      view: articleViewStore

    },
  };
  