
export default {
    data() {
        return {
            classes: ['baby', 'search', 'play', 'idea'],
            images: ['baby.svg', 'search-kids.svg', 'play-kids.svg', 'idea.svg'],

        }
    },
    props: ['index', 'desc'],
    computed: {
        indexx() {
            return this.index ? this.index : 0
        },
        isRTL() {
            return this.$i18n.locale == 'ar'
        }
    }


}
