import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-kindergarten';

export class KindergartenService {
  static async list(branchId, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query KINDERGARTEN_LIST(
          $filter: KindergartenFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          kindergartenList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              adminName {
                en
                ar
              }
              adminPhoneNumber
              openHours {
                en
                ar
              }
              activities {
                en
                ar
              }
              logo_thumbnail
              logo
              banner

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.kindergartenList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query KINDERGARTEN_FIND($id: String!) {
          kindergartenFind(id: $id) {
            id
            branchId
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            openHours {
              en
              ar
            }
            activities {
              en
              ar
            }
            logo_thumbnail
            logo
            banner

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.kindergartenFind;
  }
}
