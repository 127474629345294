
import { mapGetters } from 'vuex';
// import { i18n } from '@/i18n';
// import moment from 'moment'

export default {
  name: 'app-shop-card',
  // props: ['type', 'to'],
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },
};
