
import { mapGetters } from 'vuex';

export default {

    props: ['type', 'to', 'img'],

    data() {
        return {
            icon: null,
            name: this.type.toLowerCase(),
            unknownName: false,
            // icon_facebook: 'fab fa-facebook',
            // icon_instagram: 'fab fa-instagram-square',
            // icon_twitter: 'fab fa-twitter',
            // icon_youtube: 'fab fa-youtube',
            // icon_phone: 'fas fa-phone-alt',
            // icon_whatsapp: 'fab fa-whatsapp',
            // icon_whatsapp_square: 'fab fa-whatsapp-square',
            // icon_tiktok: 'fab fa-tiktok',
            // icon_email: 'fas fa-envelope',
            // icon_vimeo: 'fab fa-vimeo-v',
            // icon_linkedin: 'fab fa-linkedin',
            icon_facebook: 'img:/images/icons/icons8-facebook.svg',
            icon_instagram: 'img:/images/icons/icons8-instagram.svg',
            icon_twitter: 'img:/images/icons/twitter-1.svg',
            icon_youtube: 'img:/images/icons/icons8-youtube.svg',
            icon_phone: 'fas fa-phone-alt',
            icon_whatsapp: 'fab fa-whatsapp',
            icon_whatsapp_square: 'fab fa-whatsapp-square',
            icon_tiktok: 'img:/images/icons/icons8-tiktok.svg',
            icon_email: 'fas fa-envelope',
            icon_vimeo: 'img:/images/icons/icons8-vimeo.svg',
            icon_linkedin: 'fab fa-linkedin',

        }
    },
    computed: {
        ...mapGetters({
            // currentUser: 'auth/currentUser',
            socials: 'layout/socials',
        }),
    },

    created() {


        if (this.name.includes('facebook')) {
            this.icon = this.icon_facebook
            this.name = 'facebook'
        } else if (this.name.includes('instagram')) {
            this.icon = this.icon_instagram
            this.name = 'instagram'
        }
        else if (this.name.includes('twitter')) {
            this.icon = this.icon_twitter
            this.name = 'twitter'
        }
        else if (this.name.includes('youtube')) {
            this.icon = this.icon_youtube
            this.name = 'youtube'
        }
        else if (this.name.includes('whatsapp')) {
            this.icon = this.icon_whatsapp
            this.name = 'whatsapp'
        }
        else if (this.name.includes('tiktok')) {
            this.icon = this.icon_tiktok
            this.name = 'tiktok'
        }
        else if (this.name.includes('email')) {
            this.icon = this.icon_email
            this.name = 'email'
        }
        else if (this.name.includes('vimeo')) {
            this.icon = this.icon_vimeo
            this.name = 'vimeo'
        }
        else if (this.name.includes('linkedin')) {
            this.icon = this.icon_linkedin
            this.name = 'linkedin'
        }
        else {
            this.unknownName = true
        }

    }
}
