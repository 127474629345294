
import { debounce } from 'quasar';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';
import DropDownMenu from './drop-down-menu.vue';
import DropDownMenu2 from './drop-down-menu2.vue';
import DropDownMenu3 from './drop-down-menu3.vue';

export default {
  name: 'app-menu',
  data() {
    return {
      // menuOver: false,
      // menu: false,
      // menuSevices: false
      menuSports: false,
      menuOverSports: false,
      listOverSports: false,
      menuServices: false,
      menuOverServices: false,
      listOverServices: false,
      menuOther: false,
      menuOverOther: false,
      listOverOther: false,
    };
  },
  watch: {
    menuOverSports() {
      this.debounceFuncSports(
        this.menuOverSports,
        this.listOverSports,
      );
    },
    listOverSports() {
      this.debounceFuncSports(
        this.menuOverSports,
        this.listOverSports,
      );
    },
    menuOverServices() {
      this.debounceFuncServices(
        this.menuOverServices,
        this.listOverServices,
      );
    },
    listOverServices() {
      this.debounceFuncServices(
        this.menuOverServices,
        this.listOverServices,
      );
    },
    menuOverOther() {
      this.debounceFuncOther(
        this.menuOverOther,
        this.listOverOther,
      );
    },
    listOverOther() {
      this.debounceFuncOther(
        this.menuOverOther,
        this.listOverOther,
      );
    },
  },
  methods: {
    hideMenu() {
      document
        .querySelector('#drop1')
        .classList.remove('clicked');
      document
        .querySelector('#drop2')
        .classList.remove('clicked');
      document
        .querySelector('#drop3')
        .classList.remove('clicked');
    },
    goToRoute(name) {
      console.log(name);
      // debugger
      this.$router.push({
        name,
      });
      // this.$router.push('/ar/branch')
    },
    debounceFuncSports: debounce(function (
      menuOver,
      listOver,
    ) {
      this.checkMenuSports(menuOver, listOver);
    },
    100),
    debounceFuncServices: debounce(function (
      menuOver,
      listOver,
    ) {
      this.checkMenuServices(menuOver, listOver);
    },
    100),
    debounceFuncOther: debounce(function (
      menuOver,
      listOver,
    ) {
      this.checkMenuOther(menuOver, listOver);
    },
    100),
    checkMenuSports(menuOver, listOver) {
      if (menuOver || listOver) {
        this.menuSports = true;
      } else {
        this.menuSports = false;
      }
    },
    checkMenuServices(menuOver, listOver) {
      if (menuOver || listOver) {
        this.menuServices = true;
      } else {
        this.menuServices = false;
      }
    },
    checkMenuOther(menuOver, listOver) {
      if (menuOver || listOver) {
        this.menuOther = true;
      } else {
        this.menuOther = false;
      }
    },
    ...mapActions({
      doFetchSports: 'layout/doFetchSports',
    }),
    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },

  computed: {
    ...mapGetters({
      // currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      sports: 'layout/sports',
      socials: 'layout/socials',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
  async created() {
    await this.doFetchSports();
    console.log('created', this.sports);
  },
  components: {
    DropDownMenu,
    DropDownMenu2,
    DropDownMenu3,
  },
};
