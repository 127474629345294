import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-clinic';

export class ClinicService {
  static async list(branchId) {
    const response = await graphqlClient.query({
      query: gql`
        query CLINIC_LIST(
          $filter: ClinicFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          clinicList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              description {
                en
                ar
              }
              adminName {
                en
                ar
              }
              adminPhoneNumber
              branchId
              clinics

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.clinicList;
  }
}
