import { AuctionsService } from '@/modules/auction/auction-service';
import Errors from '@/shared/error/errors';
import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import 'firebase/firestore';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    auctions: [],
    currentUserPrescriptions: [],
    prescriptionsWithCreators: [],
    user: {},
    mountedTable: false,
    currentRoute: undefined,
    table: null,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    auctions: (state) => {
      return state.auctions.sort((a, b) => {
        var keyA = a.date,
          keyB = b.date;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    prescriptions: (state) => {
      return state.prescriptions.sort((a, b) => {
        var keyA = new Date(a.createdAt.seconds),
          keyB = new Date(b.createdAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    currentUserPrescriptions: (state) => {
      return state.currentUserPrescriptions.sort((a, b) => {
        var keyA = new Date(a.createdAt.seconds),
          keyB = new Date(b.createdAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    mountedTable: (state) => state.mountedTable,
    currentRoute: (state) => state.currentRoute,
    user: (state) => state.user,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.pagination = payload.pagination;
      state.auctions = payload.rows;
      state.loading = false;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },
    APPEND_TO_DRUGS(state, payload) {
      if (payload.docType == 'added') {
        state.drugs.push(payload.doc);
      }
      if (payload.docType == 'removed') {
        state.drugs = state.drugs.filter(
          (el) =>
            el.drugName.toString().toLowerCase() !==
            payload.doc.drugName.toLowerCase(),
        );
      }
      if (payload.docType == 'modified') {
        var foundIndex = state.drugs.findIndex(
          (el) =>
            el.drugName.toString().toLowerCase() ==
            payload.doc.drugName.toLowerCase(),
        );
        Object.assign(state.drugs[foundIndex], payload.doc);
      }
    },

    APPEND_TO_CURRENT_USER_PRESCRIPTIONS(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == 'added') {
        if (payload.doc.postStatus !== 'deleted') {
          state.currentUserPrescriptions.push(payload.doc);
        }
      }
      if (payload.docType == 'removed') {
        state.currentUserPrescriptions =
          state.currentUserPrescriptions.filter(
            (el) => el.id !== payload.doc.id,
          );
      }
      if (payload.docType == 'modified') {
        // console.log('modified',payload.doc.postStatus);
        if (payload.doc.postStatus == 'deleted') {
          state.currentUserPrescriptions =
            state.currentUserPrescriptions.filter(
              (el) => el.id !== payload.doc.id,
            );
        } else {
          var foundIndex =
            state.currentUserPrescriptions.findIndex(
              (el) => el.id == payload.doc.id,
            );
          Object.assign(
            state.currentUserPrescriptions[foundIndex],
            payload.doc,
          );
        }
      }
    },
    APPEND_TO_AUCTIONS(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == 'added') {
        // if(payload.doc.postStatus !== "deleted"){
        state.auctions.push(payload.doc);
        // }
      }
      if (payload.docType == 'removed') {
        state.auctions = state.auctions.filter(
          (el) => el.id !== payload.doc.id,
        );
      }
      if (payload.docType == 'modified') {
        // console.log('modified',payload.doc.postStatus);

        // if(payload.doc.postStatus == "deleted"){
        //   state.prescriptions = state.prescriptions.filter((el) => el.id !== payload.doc.id)
        // }
        // else{
        var foundIndex = state.auctions.findIndex(
          (el) => el.id == payload.doc.id,
        );
        Object.assign(
          state.auctions[foundIndex],
          payload.doc,
        );
        // }
      }
    },
    APPEND_TO_PRESCRIPTIONS_CREATOR(state, payload) {
      console.log('creator payloadddd', payload);
      state.prescriptionsWithCreators.push(payload);
    },
  },

  actions: {
    setMountedTable({ commit }, value) {
      commit('SET_MOUNTEDTABLE', value);
    },
    setCurrentRoute({ commit }, value) {
      commit('SET_CURRENTROUTE', value);
    },

    // async doFetchUser({commit},userId){
    //   PrescriptionsService.findUser(userId,(doc) => {
    //     console.log('user',doc)
    //     commit('FETCH_USER_SUCCESS',doc)

    //   })
    // },

    // async doFetch(
    //   { commit }, //   currentUserId
    // ) {
    //   try {
    //     // console.log('in do fetch',currentUserId)
    //     commit('FETCH_STARTED');
    //     AuctionsService.list(
    //       (doc, docType) => {
    //         // console.log('doc$$%%%^^^',doc)
    //         //   if(doc.createdBy == currentUserId){
    //         //     // console.log('currentUserPresc');
    //         //     commit('APPEND_TO_CURRENT_USER_PRESCRIPTIONS',{doc,docType})
    //         //   }
    //         commit('APPEND_TO_AUCTIONS', { doc, docType });
    //         commit('FETCH_SUCCESS');
    //       },
    //       // ,doc => {
    //       //   commit('APPEND_TO_PRESCRIPTIONS_CREATOR',doc)
    //       // }
    //     );
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },

    async doFetch(
      { commit },
      { branchId, action, currentPage },
    ) {
      try {
        commit('FETCH_STARTED');
        const response = await AuctionsService.list(
          branchId,
          action,
          currentPage,
        );
        console.log('this is actions rows', response);
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    bindAuctionsRef: firestoreAction((context) => {
      const db = firebase.firestore();
      return context.bindFirestoreRef(
        'auctions',
        db.collection('auctions'),
      );
    }),
  },
};
