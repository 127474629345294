import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-auctions';
export class AuctionsService {
  static async list(branchId, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query AUCTION_LIST(
          $filter: AuctionFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          auctionList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              banner
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              termsAndConditions {
                en
                ar
              }
              postDate
              expiryDate
              reservePrice
              TCTerms
              status
              views

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.auctionList;
  }
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query AUCTION_FIND($id: String!) {
          auctionFind(id: $id) {
            id
            banner
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            termsAndConditions {
              en
              ar
            }
            postDate
            expiryDate
            reservePrice
            TCTerms
            status
            views
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    console.log(response.data.auctionFind);
    return response.data.auctionFind;
  }

  static async updateViews(id, ip) {
    console.log(id, ip, 'gfghdfg');
    const response = await graphqlClient.query({
      query: gql`
        mutation AUCTION_UPDATE_VIEWS_COUNT(
          $id: String!
          $ip: String!
        ) {
          auctionUpdateViewsCount(id: $id, ip: $ip)
        }
      `,
      variables: {
        id: id,
        ip: ip,
      },
    });
    return response.data.auctionUpdateViewsCount;
  }
}
