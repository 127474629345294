
import { mapGetters, mapActions } from 'vuex';
// import { I18nUtil } from '@/shared/i18n/i18n-util';
import i18n from '@/vueI18n'
//  
import { getLanguages, getLanguageCode, setLanguageCode } from '@/i18n';
import Quasar from 'quasar'

export default {
  name: 'app-i18n-toggle',

  data() {
    return {
      lang: getLanguageCode(),
    };
  },

  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    languages() {
      return getLanguages();
    },
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },

  methods: {
    ...mapActions({
      changeCurrentLanguageCode: 'layout/changeCurrentLanguageCode',
    }),
    // doChangeLanguage(language) {
    //   i18n.locale = language
    //   localStorage.setItem('language',language)

    //   const {lang} = this.$route.params
    //   let route = ''
    //   if(lang && (lang === 'ar' || lang === 'en')){
    //     route = `/${language}${this.$route.fullPath.substring(3)}`
    //   }
    //   else{
    //     route = `/${language}${this.$route.fullPath}`
    //   }
    //   if(language === 'ar'){
    //     // document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
    //     // this.$q.lang.rtl = true
    //     // this.ar = true
    //     import(`quasar/lang/ar`).then((lang) => {
    //       // eslint-disable-next-line
    //       Quasar.lang.set(lang.default);
    //     });
    //   }
    //   else {
    //     // document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
    //     // this.$q.lang.rtl = false
    //     // this.ar = false
    //     import(`quasar/lang/en-us`).then((lang) => {
    //       // eslint-disable-next-line
    //       Quasar.lang.set(lang.default);
    //     });
    //   }
    //   this.$router.push(route)
    //   // I18nUtil.doChangeLanguage(language);
    // },

    doChangeLanguage(language) {
      // i18n.locale = language;
      // localStorage.setItem('language', language);
      setLanguageCode(language)
      this.changeCurrentLanguageCode(language)

      i18n.locale = language
      localStorage.setItem('language', language)

      const { lang } = this.$route.params
      let route = ''
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${language}${this.$route.fullPath.substring(3)}`
      }
      else {
        route = `/${language}${this.$route.fullPath}`
      }



      // const { lang } = this.$route.params;
      // let route = '';
      // if (lang && (lang === 'ar' || lang === 'en')) {
      //   route = `/${language}${this.$route.fullPath.substring(3)}`;
      // } else {
      //   route = `/${language}${this.$route.fullPath}`;
      // }

      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      this.$router.push(route);
      // I18nUtil.doChangeLanguage(language);
    },
  },
};
