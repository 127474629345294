
export default {
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    isDark() {
      return this.$q.dark.isActive
    }
  },
  data() {
    return {

    }
  },
  props: ['name']
}
