// import { ArticleService } from '@/modules/article/article-service';
// import productListExporterFields from '@/modules/product/product-list-exporter-fields';
// import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';

import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import 'firebase/firestore';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    rowsOld: [],
    count: 0,
    loading: false,
    filters: [],
    pagination: {},
    sorter: {},
    next: [],
    lastVisible: null,
    previous: [],
    firstVisible: null,
    currentUserPrescriptions: [],
    prescriptionsWithCreators: [],
    user: {},
    mountedTable: false,
    currentRoute: undefined,
    table: null,
    queryLimit: 2,
  },

  getters: {
    loading: (state) => state.loading,

    queryLimit: (state) => state.queryLimit,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsOld: (state) => state.rowsOld || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filters: (state) => state.filters,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    // article: (state) => {
    //   return state.article.sort((a, b) => {
    //     // var keyA = new Date(a.createdAt.seconds),
    //     //     keyB = new Date(b.createdAt.seconds);
    //     var keyA = a.date,
    //       keyB = b.date;
    //     if (keyA < keyB) return 1;
    //     if (keyA > keyB) return -1;
    //     return 0;
    //   });
    // },
    next: (state) => state.next,
    lastVisible: (state) => state.lastVisible,
    previous: (state) => state.previous,
    firstVisible: (state) => state.firstVisible,
    prescriptions: (state) => {
      return state.prescriptions.sort((a, b) => {
        var keyA = new Date(a.createdAt.seconds),
          keyB = new Date(b.createdAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      // state.prescriptions
    },
    currentUserPrescriptions: (state) => {
      return state.currentUserPrescriptions.sort((a, b) => {
        var keyA = new Date(a.createdAt.seconds),
          keyB = new Date(b.createdAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    mountedTable: (state) => state.mountedTable,
    currentRoute: (state) => state.currentRoute,
    user: (state) => state.user,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    FILTER_ROWS(state, payload) {
      state.rows = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    // , payload
    FETCH_STARTED(state) {
      state.loading = true;

      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },
    // , payload
    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    LOAD_MORE(state) {
      state.lastVisible =
        state.rows[state.rows.length - 1]._doc;
      state.firstVisible = state.rows[0]._doc;
    },
    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },
    APPEND_TO_DRUGS(state, payload) {
      if (payload.docType == 'added') {
        state.drugs.push(payload.doc);
      }
      if (payload.docType == 'removed') {
        state.drugs = state.drugs.filter(
          (el) =>
            el.drugName.toString().toLowerCase() !==
            payload.doc.drugName.toLowerCase(),
        );
      }
      if (payload.docType == 'modified') {
        var foundIndex = state.drugs.findIndex(
          (el) =>
            el.drugName.toString().toLowerCase() ==
            payload.doc.drugName.toLowerCase(),
        );
        Object.assign(state.drugs[foundIndex], payload.doc);
      }
    },

    APPEND_TO_CURRENT_USER_PRESCRIPTIONS(state, payload) {
      if (payload.docType == 'added') {
        if (payload.doc.postStatus !== 'deleted') {
          state.currentUserPrescriptions.push(payload.doc);
        }
      }
      if (payload.docType == 'removed') {
        state.currentUserPrescriptions = state.currentUserPrescriptions.filter(
          (el) => el.id !== payload.doc.id,
        );
      }
      if (payload.docType == 'modified') {
        if (payload.doc.postStatus == 'deleted') {
          state.currentUserPrescriptions = state.currentUserPrescriptions.filter(
            (el) => el.id !== payload.doc.id,
          );
        } else {
          var foundIndex = state.currentUserPrescriptions.findIndex(
            (el) => el.id == payload.doc.id,
          );
          Object.assign(
            state.currentUserPrescriptions[foundIndex],
            payload.doc,
          );
        }
      }
    },
    APPEND_TO_NEWS(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == 'added') {
        // if(payload.doc.postStatus !== "deleted"){
        state.rows.push(payload.doc);
        // }
      }
      if (payload.docType == 'removed') {
        state.rows = state.rows.filter(
          (el) => el.id !== payload.doc.id,
        );
      }
      if (payload.docType == 'modified') {
        // console.log('modified',payload.doc.postStatus);

        // if(payload.doc.postStatus == "deleted"){
        //   state.prescriptions = state.prescriptions.filter((el) => el.id !== payload.doc.id)
        // }
        // else{
        var foundIndex = state.rows.findIndex(
          (el) => el.id == payload.doc.id,
        );
        Object.assign(state.rows[foundIndex], payload.doc);
        // }
      }
    },
    APPEND_TO_PRESCRIPTIONS_CREATOR(state, payload) {
      state.prescriptionsWithCreators.push(payload);
    },
    RESET_LAST_AND_FIRST_VISIBLE(state) {
      state.lastVisible = null;
      state.firstVisible = null;
    },
    SLICE_ROWS(state, offset) {
      state.rows = state.rows.slice(
        offset * state.queryLimit - state.queryLimit,
        offset * state.queryLimit + 1,
      );
    },
    SET_OLD_ROWS(state) {
      state.rowsOld = state.rows;
    },
  },

  actions: {
    resetLastAndFirstVisible({ commit }) {
      commit('RESET_LAST_AND_FIRST_VISIBLE');
    },
    setMountedTable({ commit }, value) {
      commit('SET_MOUNTEDTABLE', value);
    },
    bindFiltersRef: firestoreAction((context) => {
      const db = firebase.firestore();
      return context.bindFirestoreRef(
        'filters',
        db.collection('contentType'),
      );
    }),

    doFilterSearch: firestoreAction(
      async (context, value) => {
        console.log('FILTER SEARCH');
        console.log(value);
        context.commit('FETCH_STARTED');
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, '_doc', {
            value: doc,
          });

          return data;
        };
        //FETCH ROWS
        const db = firebase.firestore();
        var filter;
        filter = db.collection('article');
        // SEARCH
        if (value.searchText != '') {
          filter = filter
            .orderBy('title.en', 'asc')
            .orderBy('createdAt', 'desc')
            .where('title.en', '>=', value.searchText)
            .where('title.en', '<', value.endcode);
        } else {
          filter = filter.orderBy('createdAt', 'desc');
        }
        // SPORTS CATEGORY FILTER
        if (value.sportsFilter != '') {
          if (value.sportsFilterType === 'category') {
            filter = filter.where(
              'categoryId',
              '==',
              value.sportsFilter,
            );
          } else if (
            value.sportsFilterType === 'subCategory'
          ) {
            filter = filter.where(
              'subCategoryId',
              '==',
              value.sportsFilter,
            );
          }
        }
        // FIRST LOAD
        if (context.getters.lastVisible == null || value.navigateBackwardForwardBoolean) {
          filter = filter.limit(
            context.getters.queryLimit * value.pageNumber,
          );
        } else {
          filter = filter
            .limit(context.getters.queryLimit)
            .startAfter(context.getters.lastVisible);
        }
        // FILTER TABS
        if (value.id != 'all') {
          filter = filter.where(
            'contentTypeId',
            '==',
            value.id,
          );
        }

        await context.bindFirestoreRef('rows', filter, {
          serialize: customSerializer,
        });
        console.log(context.getters.rows);
        if (context.getters.lastVisible == null || value.navigateBackwardForwardBoolean) {
          context.commit('SLICE_ROWS', value.pageNumber);
        }
        if (context.getters.rows.length > 0) {
          context.commit('LOAD_MORE');

          // FETCH NEXT ARTICLE
          var next = db.collection('article');
          // SEARCH
          if (value.searchText != '') {
            next = next
              .orderBy('title.en', 'asc')
              .orderBy('createdAt', 'desc')
              .where('title.en', '>=', value.searchText)
              .where('title.en', '<', value.endcode);
          } else {
            next = next.orderBy('createdAt', 'desc');
          }
          // SPORTS CATEGORY FILTER
          if (value.sportsFilter != '') {
            if (value.sportsFilterType === 'category') {
              next = next.where(
                'categoryId',
                '==',
                value.sportsFilter,
              );
            } else if (
              value.sportsFilterType === 'subCategory'
            ) {
              next = next.where(
                'subCategoryId',
                '==',
                value.sportsFilter,
              );
            }
          }
          // TAB FILTER
          if (value.id != 'all') {
            next = next.where(
              'contentTypeId',
              '==',
              value.id,
            );
          }

          next = next
            .limit(1)
            .startAfter(context.getters.lastVisible);

          await context.bindFirestoreRef('next', next, {
            serialize: customSerializer,
          });
          console.log(context.getters.next);

          console.log(context.getters.next);
          // FETCH PREVIOUS ARTICLE
          var previous = db.collection('article');
          // SEARCH
          if (value.searchText != '') {
            previous = previous
              .orderBy('title.en', 'asc')
              .orderBy('createdAt', 'desc')
              .where('title.en', '>=', value.searchText)
              .where('title.en', '<', value.endcode);
          } else {
            previous = previous.orderBy(
              'createdAt',
              'desc',
            );
          }
          //SPORTS CATEGORY FILTER
          if (value.sportsFilter != '') {
            if (value.sportsFilterType === 'category') {
              previous = previous.where(
                'categoryId',
                '==',
                value.sportsFilter,
              );
            } else if (
              value.sportsFilterType === 'subCategory'
            ) {
              previous = previous.where(
                'subCategoryId',
                '==',
                value.sportsFilter,
              );
            }
          }
          // TAB FILTER
          if (value.id != 'all') {
            previous = previous.where(
              'contentTypeId',
              '==',
              value.id,
            );
          }

          previous = previous
            .limitToLast(1)
            .endBefore(context.getters.lastVisible);

          await context.bindFirestoreRef(
            'previous',
            previous,
            {
              serialize: customSerializer,
            },
          );

          console.log(context.getters.previous);
        }

        context.commit('FETCH_SUCCESS');
      },
    ),

    doFilterSearchPrevious: firestoreAction(
      async (context, value) => {
        console.log('FILTER SEARCH PREVIOUS');
        context.commit('FETCH_STARTED');
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, '_doc', {
            value: doc,
          });

          return data;
        };
        // FETCH PREVIOUS ROWS
        const db = firebase.firestore();
        var filter;
        filter = db.collection('article');
        // SEARCH
        if (value.searchText != '') {
          filter = filter
            .orderBy('title.en', 'asc')
            .orderBy('createdAt', 'desc')
            .where('title.en', '>=', value.searchText)
            .where('title.en', '<', value.endcode);
        } else {
          filter = filter.orderBy('createdAt', 'desc');
        }
        // FILTER CATEGORY
        if (value.sportsFilter != '') {
          if (value.sportsFilterType === 'category') {
            filter = filter.where(
              'categoryId',
              '==',
              value.sportsFilter,
            );
          } else if (
            value.sportsFilterType === 'subCategory'
          ) {
            filter = filter.where(
              'subCategoryId',
              '==',
              value.sportsFilter,
            );
          }
        }
        filter = filter
          .limitToLast(context.getters.queryLimit)
          .endBefore(context.getters.firstVisible);
        //FILTER TAB
        if (value.id != 'all') {
          filter = filter.where(
            'contentTypeId',
            '==',
            value.id,
          );
        }

        await context.bindFirestoreRef('rows', filter, {
          serialize: customSerializer,
        });
        if (context.getters.lastVisible == null) {
          context.commit('SLICE_ROWS', value.pageNumber);
        }
        if (context.getters.rows.length > 0) {
          context.commit('LOAD_MORE');
        }
        // FETCH NEXT ARTICLE
        var next = db.collection('article');
        //SEARCH
        if (value.searchText != '') {
          next = next
            .orderBy('title.en', 'asc')
            .orderBy('createdAt', 'desc')
            .where('title.en', '>=', value.searchText)
            .where('title.en', '<', value.endcode);
        } else {
          next = next.orderBy('createdAt', 'desc');
        }
        // SPORTS FILTER CATEGORY
        if (value.sportsFilter != '') {
          if (value.sportsFilterType === 'category') {
            next = next.where(
              'categoryId',
              '==',
              value.sportsFilter,
            );
          } else if (
            value.sportsFilterType === 'subCategory'
          ) {
            next = next.where(
              'subCategoryId',
              '==',
              value.sportsFilter,
            );
          }
        }
        // TAB FILTER
        if (value.id != 'all') {
          next = next.where(
            'contentTypeId',
            '==',
            value.id,
          );
        }

        next = next
          .limit(1)
          .startAfter(context.getters.lastVisible);

        await context.bindFirestoreRef('next', next, {
          serialize: customSerializer,
        });

        console.log(context.getters.next);
        //FETCH PREVIOUS ARTICLE
        var previous = db.collection('article');
        //SEARCH
        if (value.searchText != '') {
          previous = previous
            .orderBy('title.en', 'asc')
            .orderBy('createdAt', 'desc')
            .where('title.en', '>=', value.searchText)
            .where('title.en', '<', value.endcode);
        } else {
          previous = previous.orderBy('createdAt', 'desc');
        }
        //SPORTS CATEOGRY FILTER
        if (value.sportsFilter != '') {
          if (value.sportsFilterType === 'category') {
            previous = previous.where(
              'categoryId',
              '==',
              value.sportsFilter,
            );
          } else if (
            value.sportsFilterType === 'subCategory'
          ) {
            previous = previous.where(
              'subCategoryId',
              '==',
              value.sportsFilter,
            );
          }
        }
        //TAB FILTER
        if (value.id != 'all') {
          previous = previous.where(
            'contentTypeId',
            '==',
            value.id,
          );
        }

        previous = previous
          .limitToLast(1)
          .endBefore(context.getters.lastVisible);

        await context.bindFirestoreRef(
          'previous',
          previous,
          {
            serialize: customSerializer,
          },
        );

        console.log(context.getters.previous);

        context.commit('FETCH_SUCCESS');
      },
    ),

    setCurrentRoute({ commit }, value) {
      commit('SET_CURRENTROUTE', value);
    },

    doLoadMore: firestoreAction(
      async (context, payload) => {
        context.commit("SET_OLD_ROWS")
        var filteredArray;
        if (payload.id != 'all') {
          filteredArray = context.getters.filters.filter(
            function(itm) {
              return itm.name.en == payload.id;
            },
          );
          payload.id = filteredArray[0].id;
        }
        return await context.dispatch(
          'doFilterSearch',
          payload,
        );
      },
    ),
    doFetchPrevious: firestoreAction(
      async (context, payload) => {
        context.commit("SET_OLD_ROWS")
        if (payload.id != 'all') {
          var filteredArray = context.getters.filters.filter(
            function(itm) {
              return itm.name.en == payload.id;
            },
          );
          payload.id = filteredArray[0].id;
        }
        return await context.dispatch(
          'doFilterSearchPrevious',
          payload,
        );
      },
    ),
  },
};
