import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-parking';

export class ParkingService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_RESTAURANTS {
          restaurantList {
            count
            rows {
              description {
                en
                ar
              }
              id
              location {
                en
                ar
              }
              menuUrl
              name {
                en
                ar
              }
              number
              thumbnail
            }
          }
        }
      `,
    });
    return response.data.restaurantList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
          parkingFind(id: $id) {
            id
            branchId
            banner
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            annualSubscription
            openHours {
              en
              ar
            }
            places {
              en
              ar
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
      },
    });
    return response.data.parkingFind;
  }
}
