
export default {
  props: ['record', 'res'],
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    highestDiscount() {
      if (this.record.offers) {
        return Math.max.apply(
          Math,
          this.record.offers.map((e) => e.discount),
        );
      }
      return false;
    },
  },
  methods: {
    setAltImg(event) {
      event.target.src = '/images/default/download.png';
    },
  },
};
