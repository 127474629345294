import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const AdminListPage = () => import('@/modules/404/404.vue');

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: '404',
        path: '404',
        component: AdminListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') + ' | ' + i18n('menu.404');
          next();
        },
      },
    ],
  },
];
