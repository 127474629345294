
import { mapGetters, mapActions } from 'vuex';

// import firebase from 'firebase/app';
// import 'firebase/firestore'
// import moment from 'moment';

export default {
  name: 'app-layout',

  data() {
    return {
      drawer: false,
      left: false,
      miniState: false,
    };
  },
  computed: {
    ...mapGetters({
      // currentUser: 'auth/currentUser',
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      // settings:'settings/settings',
    }),
    isDark() {
      return this.$q.dark.isActive;
    },
    checkRoute() {
      console.log('route', this.$route);

      if (
        this.$route.path.includes('/news/') ||
        this.$route.path.includes('/branch/') ||
        this.$route.path.includes('/auctions/') ||
        this.$route.path.includes('/article/')
      ) {
        return true;
      } else {
        return false;
      }
    },

    asideWidth() {
      if (!this.isMobile) {
        return 220;
      }
      return 200;
    },
    currentBrowser() {
      return localStorage.getItem('browser');
    },
  },
  methods: {
    ...mapActions({
      // productListener:'layout/productListener',
      // doFindSettings:'settings/doFind',
      // doRefreshCurrentUser: 'auth/doRefreshCurrentUser'
    }),

    drawerClick(e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false;
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation();
      }
    },
  },
};
