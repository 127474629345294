import { i18n } from '@/i18n';
import { Notify } from 'quasar';

export default class Message {
  static success(payload) {
    Notify.create({
      message: payload,
      color: 'white',
      textColor: 'green',
      timeout: 6000,
      position: 'top-right',
      icon: 'fas fa-check-circle',
      // actions:[
      //   { label: 'Reply', color: 'cyan', handler: () => { /*  console.log('wooow') */ } },
      //   { label: 'Dismiss', color: 'cyan', handler: () => { /*  console.log('wooow') */ } }
      // ]
    });
  }

  static error(payload) {
    let message = payload;
    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }

    Notify.create({
      message: message,
      color: 'white',
      textColor: 'red',
      timeout: 6000,
      position: 'top-right',
      icon: 'fas fa-times-circle',
      // icon: 'fas fa-exclamation-circle',
      // actions:[
      //   { label: 'Reply', color: 'cyan', handler: () => { /*  console.log('wooow') */ } },
      //   { label: 'Dismiss', color: 'cyan', handler: () => { /*  console.log('wooow') */ } }
      // ]
    });
  }
}