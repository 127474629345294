import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-media';

export class MediaService {
  static async list(action, page) {
    const response = await graphqlClient.query({
      query: gql`
        query ALBUM_LIST(
          $filter: AlbumFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          albumList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              images
              videos

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: action,
          page: page,
        },
      },
    });
    return response.data.albumList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ALBUM_FIND($id: String!) {
          albumFind(id: $id) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            images
            videos

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.albumFind;
  }
}
