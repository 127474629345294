import {
  firestoreAction,
  vuexfireMutations,
} from 'vuexfire';
import firebase from 'firebase/app';
import { MediaService } from '@/modules/media/media-service';
import Errors from '@/shared/error/errors';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    contentTypeRows: [],
    categories: {},
    rowsFromRoute: [],
    //#region
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    //#endregion
    count: 0,
    loading: false,
    loadingCurrent: false,
    loadingNew: false,
    filter: {},
    pagination: {},
    sorter: {},
    limit: {},
    table: null,

    // rows:[],
    mountedTable: false,

    record: null,
  },

  getters: {
    loading: (state) => state.loading,
    record: (state) => state.record,
    // loadingCurrent: (state) => state.loadingCurrent,
    // loadingNew: (state) => state.loadingNew,
    mountedTable: (state) => state.mountedTable,
    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    contentTypeRows: (state) => state.contentTypeRows || [],
    categories: (state) => state.categories || {},
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    loadMoreLastRecord: (state) =>
      state.loadMoreLastRecord || null,
    loadFirstRecord: (state) =>
      state.loadFirstRecord || null,
    recordAfterLast: (state) => state.recordAfterLast || [],
    recordBeforeFirst: (state) =>
      state.recordBeforeFirst || [],

    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,
    limit: (state) => state.limit,
    // limit: (state) => {
    //   const pagination = state.pagination;

    //   if (!pagination || !pagination.pageSize) {
    //     return INITIAL_PAGE_SIZE;
    //   }

    //   return pagination.pageSize;
    // },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    // drugsCurrent: (state) => state.drugsCurrent,
    // drugsNew: (state) => state.drugsNew,

    // searchFilter: (state)=> state.searchFilter,
    // drugIndexNewSettings: (state) => state.drugIndexNewSettings,
    // drugIndexCurrentSettings: (state) => state.drugIndexCurrentSettings,

    // rows: (state) => state.rows
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_CURRENT_STARTED(state) {
      // console.log('fetch current started')
      state.loadingCurrent = true;
    },
    FETCH_NEW_STARTED(state) {
      // console.log('fetch new started')
      state.loadingNew = true;
    },
    UPDATE_DRUG_INDEX_NEW(state, payload) {
      state.drugIndexNewSettings = payload;
    },
    UPDATE_DRUG_INDEX_CURRENT(state, payload) {
      state.drugIndexCurrentSettings = payload;
    },
    FETCH_SUCCESS(state, payload) {
      // console.log('ftech succes',payload)
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
      state.pagination = payload.pagination;
    },
    FETCH_CURRENT_SUCCESS(state) {
      // console.log('fetch currentsucces')
      state.loadingCurrent = false;
    },
    FETCH_NEW_SUCCESS(state) {
      // console.log('fetch new succes')
      state.loadingNew = false;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_LIMIT(state, payload) {
      state.limit = payload;
    },
    SET_SEARCH(state, payload) {
      state.searchFilter = payload;
      // value.company.toString().toLowerCase().includes(this.search.toLowerCase())
      state.drugs = state.drugs.filter((el) =>
        el.drugName
          .toString()
          .toLowerCase()
          .includes(payload.toLowerCase()),
      );
      // console.log('state drugs after',state.drugs)
    },

    APPEND_TO_OFFERS(state, payload) {
      if (payload.docType == 'added') {
        // console.log('added')
        // const found = state.drugsCurrent.some(el => el.drugName.toString().toLowerCase() === payload.doc.drugName.toString().toLowerCase());
        // if (!found)
        state.rows.push(payload.doc);
      }
      if (payload.docType == 'removed') {
        // console.log('removed')

        state.rows = state.rows.filter(
          (el) =>
            el.title.en.toString().toLowerCase() !==
            payload.doc.title.en.toString().toLowerCase(),
        );
      }
      if (payload.docType == 'modified') {
        console.log('modified');
        console.log('payload title', payload.doc.title);

        // state.drugsCurrent = state.drugsCurrent.filter((el) => el.drugName.toString().toLowerCase() !== payload.doc.drugName.toLowerCase())
        // state.drugsCurrent.push(payload.doc)
        var foundIndex = state.rows.findIndex(
          (el) =>
            el.title.en.toString().toLowerCase() ==
            payload.doc.title.en.toLowerCase(),
        );
        // console.log('in found index',foundIndex)
        Object.assign(state.rows[foundIndex], payload.doc);
      }
    },

    APPEND_TO_CATEGORIES(state, payload) {
      state.categories[payload.contentTypeId] =
        payload.categories;
      // console.log('state regions',state.regions);
    },

    //#region VuexfireMutations
    ...vuexfireMutations,
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastRecord =
        state.rows[state.rows.length - 1]._doc;
      state.recordAfterLast =
        state.rows[state.rows.length - 1];
    },
    SET_LOADFIRSTRECORD(state) {
      state.loadFirstRecord = state.rows[0]._doc;
    },
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null;
      state.loadFirstRecord = null;
    },
    REMOVELASTELEMENT(state) {
      state.rows.pop();
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
      state.pagesNumber = Math.ceil(
        state.totalRecords / 10,
      );
    },
    SET_PAGE_COUNT(state, payload) {
      state.pageCount = payload;
    },
    SET_ROWS(state, payload) {
      state.rows = payload;
      // debugger
    },
    SET_ADDED_ROW_ID(state, payload) {
      state.addedRowId = payload;
    },
    SET_ADDED_CREATOR_ID(state, payload) {
      state.addedCreatorId = payload;
    },
    async SET_CREATOR_TO_ROW(state) {
      const creator = await firebase
        .firestore()
        .collection('user')
        .doc(state.addedCreatorId)
        .get();
      state.rowsCreator.push(creator.data());
      const addedRow = state.rows.find(
        (el) => el.id == state.addedRowId,
      );
      addedRow['creator'] = creator.data();
    },
    FIND_STARTED(state) {
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.loading = false;
      state.record = payload;
    },
    //#endregion
  },

  actions: {
    setSearch({ commit }, value) {
      commit('SET_SEARCH', value);
    },
    setMountedTable({ commit }, value) {
      commit('SET_MOUNTEDTABLE', value);
    },
    doFetchContentType: firestoreAction(
      async ({ bindFirestoreRef, getters, commit }) => {
        commit('FETCH_STARTED');
        const db = firebase.firestore();
        const contentTypeCollection =
          db.collection('contentType');
        await bindFirestoreRef(
          'contentTypeRows',
          contentTypeCollection,
          // { serialize: customSerializer }
        );
        console.log('rows ###', getters.contentTypeRows);
        commit('FETCH_SUCCESS');
      },
    ),
    // doFetchCategory: firestoreAction(async ({bindFirestoreRef,getters,commit}) => {
    //   commit('FETCH_STARTED')
    //   // const contentTypes = getters.contentTypeRows
    //   const db = firebase.firestore()
    //   const contentTypeCollection = db.collection('contentType')
    //   // contentTypes.forEach((contentType) =>{

    //   // })

    //   await bindFirestoreRef(
    //     'contentTypeRows',
    //     contentTypeCollection,
    //     // { serialize: customSerializer }
    //   );
    //   console.log('rows ###', getters.contentTypeRows);
    //   commit('FETCH_SUCCESS')
    // }),
    async doFetchCategory({ commit, getters }) {
      getters.contentTypeRows.forEach(async (doc) => {
        const contentTypeId = doc.id;
        const categories = await firebase
          .firestore()
          .collection(
            `contentType/${contentTypeId}/category`,
          )
          .get();
        let contentCategories = [];
        if (categories.docs.length > 0) {
          categories.docs.forEach(async (doc) => {
            let category = doc.data();
            const subCategories = await firebase
              .firestore()
              .collection(
                `contentType/${contentTypeId}/category/${category.id}/subCategories`,
              )
              .get();
            const subCategoriesArray =
              subCategories.docs.map((subCategory) =>
                subCategory.data(),
              );
            category['subCategories'] = subCategoriesArray;
            contentCategories.push(category);
          });
        }
        commit('APPEND_TO_CATEGORIES', {
          contentTypeId: doc.id,
          categories: contentCategories,
        });
      });
    },

    // doFind: firestoreAction(
    //   async ({ bindFirestoreRef, commit }, payload) => {
    //     commit('FIND_STARTED');
    //     console.log('in do find', payload.id);
    //     // debugger
    //     const db = firebase.firestore();
    //     const recordDocument = db
    //       .collection('branch')
    //       .doc(payload.id);
    //     await bindFirestoreRef('record', recordDocument);
    //     // console.log('getters record', getters.record);
    //   },
    // ),
    async doFind({ commit }, id) {
      try {
        commit('FETCH_STARTED');
        console.log('ID IS ' + id);
        const response = await MediaService.find(id);
        console.log(response);
        commit('FIND_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    // doFetch: firestoreAction(
    //   async ({ bindFirestoreRef, getters, commit }) => {
    //     commit('FETCH_STARTED');
    //     const customSerializer = (doc) => {
    //       const data = doc.data();
    //       Object.defineProperty(data, '_doc', {
    //         value: doc,
    //       });
    //       return data;
    //     };
    //     const db = firebase.firestore();
    //     var articleCollection = db.collection('article');
    //     await bindFirestoreRef('rows', articleCollection, {
    //       serialize: customSerializer,
    //     });
    //     console.log('rows ###', getters.rows);
    //     commit('FETCH_SUCCESS');
    //   },
    // ),

    async doFetch({ commit }, { action, page }) {
      try {
        commit('FETCH_STARTED');
        const response = await MediaService.list(
          action,
          page,
        );
        console.log(response);
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    doFetchFromRoute: firestoreAction(
      async ({ bindFirestoreRef, commit }, payload) => {
        commit('FETCH_STARTED');
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, '_doc', {
            value: doc,
          });
          return data;
        };
        const db = firebase.firestore();
        var offerCollection = null;
        const endAt = payload.page * payload.limit;
        offerCollection = db
          .collection('offer')
          .orderBy('createdAt', 'desc')
          .limit(endAt);
        await bindFirestoreRef(
          'rowsFromRoute',
          offerCollection,
          { serialize: customSerializer },
        );
        commit('FETCH_SUCCESS');
      },
    ),
    getPreviousRecordsBatch: firestoreAction(
      async ({ bindFirestoreRef, getters }, payload) => {
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, '_doc', {
            value: doc,
          });
          return data;
        };
        const db = firebase.firestore();
        var offerCollection = null;
        offerCollection = db
          .collection('offer')
          .orderBy('createdAt', 'desc')
          .endBefore(getters.loadFirstRecord)
          .limitToLast(payload.limit || 9);
        await bindFirestoreRef('rows', offerCollection, {
          serialize: customSerializer,
        });
      },
    ),
    setLoadMoreLast({ commit }) {
      commit('SET_LOADMORE_LAST');
    },
    resetCursor({ commit }) {
      commit('RESET_CURSOR');
    },
    setLoadFirstRecord({ commit }) {
      commit('SET_LOADFIRSTRECORD');
    },
    setDisableNext: firestoreAction(
      async ({ getters, bindFirestoreRef }) => {
        const db = firebase.firestore();
        var offerCollection = null;
        offerCollection = db
          .collection('offer')
          .orderBy('createdAt', 'desc')
          .startAfter(getters.loadMoreLastRecord)
          .limit(1);
        await bindFirestoreRef(
          'recordAfterLast',
          offerCollection,
        );
      },
    ),
    setDisablePrevious: firestoreAction(
      async ({ getters, bindFirestoreRef }) => {
        const db = firebase.firestore();
        var offerCollection = null;
        offerCollection = db
          .collection('offer')
          .orderBy('createdAt', 'desc')
          .endBefore(getters.loadFirstRecord)
          .limitToLast(1);
        await bindFirestoreRef(
          'recordBeforeFirst',
          offerCollection,
        );
      },
    ),
    setRows({ commit }, payload) {
      commit('FETCH_STARTED');
      commit('SET_ROWS', payload);
      commit('FETCH_SUCCESS');
    },

    // async doFetch(
    //   { commit },
    //   { filter, keepPagination } = {},

    // ) {
    //   console.log('in do fetch current store&&&&&')
    //   try {
    //     commit('FETCH_STARTED', { filter, keepPagination });

    //      await OfferService.listOffers((doc,docType) => {
    //       commit('APPEND_TO_OFFERS',{doc,docType})
    //       commit('FETCH_SUCCESS');
    //       // console.log('doc$$%%%^^^',doc)
    //     })

    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },
  },
};
