import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-news';
import graphqlClient2 from '@/shared/graphql/client-category';

export class NewsService {
  static async list(filterArray, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query ARTICLE_LIST(
          $filter: ArticleFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          articleList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              title {
                en
                ar
              }
              content {
                en
                ar
              }
              socialPostId
              postDetails {
                id
                platform
                postUrl
              }
              postDate
              platforms
              images
              videoUrl
              tags
              categories {
                id
                name {
                  en
                  ar
                }
              }
              inReview
              showAuthorName

              likes
              views
              commentsCount

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: {
          categories: filterArray,
        },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'desc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.articleList;
  }

  static async listCategories() {
    const response = await graphqlClient2.query({
      query: gql`
        query CATEGORY_LIST(
          $filter: CategoryFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          categoryList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.categoryList;
  }
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ARTICLE_FIND($id: String!) {
          articleFind(id: $id) {
            id
            title {
              en
              ar
            }
            content {
              en
              ar
            }
            postDate
            platforms
            images
            videoUrl
            tags
            categories {
              id
              name {
                en
                ar
              }
            }
            inReview
            showAuthorName

            likes
            views
            commentsCount

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.articleFind;
  }

  static async updateViews(id, ip) {
    const response = await graphqlClient.query({
      query: gql`
        mutation ARTICLE_UPDATE_VIEWS_COUNT(
          $id: String!
          $ip: String!
        ) {
          articleUpdateViewsCount(id: $id, ip: $ip)
        }
      `,
      variables: {
        id: id,
        ip: ip,
      },
    });
    return response.data.articleUpdateViewsCount;
  }

  static async updateLikes(id, ip) {
    const response = await graphqlClient.query({
      query: gql`
        mutation ARTICLE_LIKE($id: String!, $ip: String!) {
          articleLike(id: $id, ip: $ip)
        }
      `,
      variables: {
        id,
        ip,
      },
    });
    return response.data.articleLike;
  }

  static async unlike(id, ip) {
    const response = await graphqlClient.query({
      query: gql`
        mutation ARTICLE_UNLIKE(
          $id: String!
          $ip: String!
        ) {
          articleUnlike(id: $id, ip: $ip)
        }
      `,
      variables: {
        id,
        ip,
      },
    });
    return response.data.articleLike;
  }
}
