
import importerStatuses from '@/shared/importer/importer-statuses';

export default {
  name: 'app-importer-status-row',

  props: ['value', 'errorMessage'],

  computed: {
    isPending() {
      return this.value === importerStatuses.PENDING;
    },

    isImported() {
      return this.value === importerStatuses.IMPORTED;
    },

    isError() {
      return this.value === importerStatuses.ERROR;
    },
  },
};
