import auctionsListStore from '@/modules/auction/auction-list-store';
import auctionViewStore from '@/modules/auction/auction-view-store';

export default {
    namespaced: true,
  
    modules: {
      list: auctionsListStore,
      view: auctionViewStore

    },
  };
  