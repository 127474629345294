
import gsap from 'gsap';

export default {
    mounted() {
        const t1 = gsap.timeline({ defaults: { ease: "power1.out" } })
        t1.fromTo(".text", { x: "100%" }, { x: "0%", duration: 0.35, stagger: 0.07 })
    }
    ,
    // computed: {
    //     name() {
    //         return this.$router.currentRoute.name
    //     }
    // }
    // created() {
    //     const t1 = gsap.timeline({ defaults: { ease: "power1.out" } })
    //     t1.fromTo(".text", { x: "100%" }, { x: "0%", duration: 0.5, stagger: 0.1 })
    // }
}
