import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-telecom';

export class TelecomService {
  static async list(branchId, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query TELECOM_LIST(
          $filter: TelecomFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          telecomList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              logo
              banner
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              adminName {
                en
                ar
              }
              adminPhoneNumber
              openHours {
                en
                ar
              }

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.telecomList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query TELECOM_FIND($id: String!) {
          telecomFind(id: $id) {
            id
            branchId
            logo
            banner
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            openHours {
              en
              ar
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
      },
    });
    return response.data.telecomFind;
  }
}
