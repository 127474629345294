
export default {
    data() {
        return {
            isThemeDark: false,
            toggleBtnEl: document.querySelector(".theme-toggle--button"),
            shapeEl: document.querySelector(".shape")
        }
    },
    methods: {
        toggleTheme() {
            this.isThemeDark = !this.isThemeDark;
            const replacedClass = this.isThemeDark ? "moon" : "sun"
            const replacedWithClass = this.isThemeDark ? "sun" : "moon"
            this.shapeEl.classList.replace(replacedClass, replacedWithClass);
            console.log('cliked')

        }
    }
}










