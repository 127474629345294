import Layout from '@/modules/layout/components/layout';
// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n';

const HomePage = () =>
  import('@/modules/home/components/home-page.vue');
  const ComingSoon = () =>
  import('@/modules/home/components/coming-soon2.vue');
export default [
  {
    // path: '',
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.home') 
          next();
        },
      },
      {
        name: 'coming-soon',
        path: 'coming-soon',
        component: ComingSoon,
        exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.home') 
          next();
        },
      },
    ],
  },
];
