
import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';
import moment from 'moment';
import { ArticleModel } from '@/modules/article/article-model';

export default {
  name: 'app-article-card',
  props: ['record'],
  data() {
    return {
      likedArticle: false,
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    isArticle() {
      if (this.$route.path.includes('article')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    doLikeArticle(id) {
      console.log('id', id);
      this.likedArticle = !this.likedArticle;
    },
    presenter(row, fieldName) {
      const val = ArticleModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = ArticleModel.presenter(row, 'title');
        return val['en'] ? val['en'] : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(row, 'title');
        return val['ar'] ? val['ar'] : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      console.log('isRTL', this.isRTL);
      if (!this.isRTL) {
        const val = ArticleModel.presenter(
          row,
          'description',
        );
        return val['en'] ? val['en'] : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(
          row,
          'description',
        );
        return val['ar'] ? val['ar'] : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ArticleModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      console.log('lang', localStorage.getItem('language'));
      console.log(
        moment
          .unix(date.seconds)
          .format('dddd D MMM YYYY hh:mm A'),
      );
      return date
        ? moment
            .unix(date.seconds)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
  },
};
