
import { mapActions, mapGetters } from 'vuex';
import darkmode from './darkmode.vue';
import i18n from '@/vueI18n';
import gsap from 'gsap';
import sportsGameSub from './mobile-menu-sports-sub.vue';
import servicesSub from './mobile-menu-services-sub';
import otherSub from './mobile-menu-other-sub.vue';
import selectBranch from './mobile-menu-selectBranch-sub.vue';
import emptyMenu from './mobile-menu-empty.vue';
// import { Slide } from 'vue-burger-menu';

export default {
  components: {
    darkmode,
    sportsGameSub,
    otherSub,
    emptyMenu,
    selectBranch,
    servicesSub,
  },
  data: () => ({
    open: false,
    windowWidth: 300,
    menuIsActive: false,
    curruntSubMenu: 'services-sub',
  }),
  mounted() {
    this.windowWidth = `${window.innerWidth}`;
  },
  methods: {
    ...mapActions({
      doFetchSports: 'layout/doFetchSports',
    }),
    hideMenu() {
      document
        .querySelector('#drop3')
        .classList.remove('clicked');
      // document.querySelector('#drop4').classList.remove('clicked')
    },

    showMenu() {
      this.menuIsActive = true;
      const t1 = gsap.timeline({
        defaults: { ease: 'power1.out' },
      });
      this.isRTL
        ? t1.fromTo(
            '.text',
            { x: '-100%' },
            { x: '0%', duration: 0.35, stagger: 0.1 },
          )
        : t1.fromTo(
            '.text',
            { x: '100%' },
            { x: '0%', duration: 0.35, stagger: 0.1 },
          );
    },
    hideIt() {
      this.menuIsActive = false;
    },

    setActive(element) {
      let items =
        document.getElementsByClassName('menu-item');
      for (let i = 0; i < items.length; i++) {
        items[i].className = items[i].className.replace(
          'active',
          '',
        );
      }
      element.target.classList.add('active');
      this.curruntSubMenu = 'empty-menu';
    },

    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
    goToRoute(name) {
      // debugger
      this.$router.push({
        name,
      });
      this.hideIt();
      // this.curruntSubMenu = 'empty-menu'
      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  computed: {
    ...mapGetters({
      sports: 'layout/sports',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
  async created() {
    await this.doFetchSports();
  },
};
