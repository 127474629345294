import { render, staticRenderFns } from "./social-account-card.vue?vue&type=template&id=21f61a37&scoped=true&"
import script from "./social-account-card.vue?vue&type=script&lang=js&"
export * from "./social-account-card.vue?vue&type=script&lang=js&"
import style0 from "./social-account-card.vue?vue&type=style&index=0&id=21f61a37&prod&lang=scss&scoped=true&"
import style1 from "./social-account-card.vue?vue&type=style&index=1&id=21f61a37&prod&lang=scss&scoped=true&"
import style2 from "./social-account-card.vue?vue&type=style&index=2&id=21f61a37&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f61a37",
  null
  
)

export default component.exports
import {QCard,QCardSection,QAvatar,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QAvatar,QIcon})
