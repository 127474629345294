
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            open: false,
            currentBranchName: null
        }
    },

    computed: {
        ...mapGetters({
            currentBranch: 'branch/view/branch',
            rows: 'branch/view/rows'
        }),
        isRTL() {
            return this.$i18n.locale == 'ar'
        },

    },

    methods: {
        ...mapActions({
            changeBranch: 'branch/view/changeBranch',
            doFetch: 'branch/view/doFetch'
        }),
        changeBranchh(id) {
            this.changeBranch(id)
            localStorage.setItem('current_branch', id);
            this.currentBranchName = this.rows.find(item => item.id === this.currentBranch).name

        },
        toggle() {
            this.open = !this.open
        }
    },
    async created() {
        await this.doFetch()
        if (this.rows.length > 0) { this.currentBranchName = this.rows.find(item => item.id === this.currentBranch).name }


        console.log(this.currentBranchName)


    }
}
