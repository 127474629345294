import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';
const medicalDiscountViewPage = () =>
  import(
    '@/modules/medical-discounts/components/medical-discount-view.vue'
  );
const MedicalDiscountsListPage = () =>
  import(
    '@/modules/medical-discounts/components/medical-discounts-list-page.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'medical-discounts',
        path: 'medical-discounts',
        component: MedicalDiscountsListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.medical-discounts');
          next();
        },
      },
      {
        name: 'medical-discounts-view',
        path: 'medical-discounts/:id',
        component: medicalDiscountViewPage,
      },
    ],
  },
];
