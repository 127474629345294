// import { NewsService } from '@/modules/news/news-service';
// import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import { firestorePlugin } from 'vuefire';
import Vue from 'vue';

Vue.use(firestorePlugin)



export default {
  namespaced: true,

  state: {
    loading: false,
    userLoading:false,
    record: null,
    user: null,
    likes: [],
    isLiked: null,
    viewer: null
  },

  getters: {
    record: (state) => state.record,
    user: (state) => state.user,
    loading: (state) => !!state.loading,
    likes: (state) => state.likes,
    isLiked: (state) => state.isLiked,
    viewer: (state) => state.viewer
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state,payload) {
     state.record = payload;
      state.loading = false;
    },

    FIND_ARTICLE_DETAILS_STARTED(state) {
       state.loading = true;
     },

     FIND_ARTICLE_DETAILS_SUCCESS(state) {
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_USER_STARTED(state) {
      state.user = null;
      state.userLoading = true;
    },

    FIND_USER_SUCCESS(state, payload) {
      state.user = payload;
      console.log('state user',state.user)
      state.userLoading = false;
    },

    FIND_USER_ERROR(state) {
      state.user = null;
      state.userLoading = false;
    },
  },

  actions: {
    // async doFind({ commit ,dispatch},payload) {
      doFind:  firestoreAction(async ({bindFirestoreRef,commit,dispatch} , payload) => {

      commit('FIND_ARTICLE_DETAILS_STARTED')
      const db = firebase.firestore()
      // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
      const record = db.collection(`article`).doc(payload.articleId)
      
     
      await bindFirestoreRef(
        'record',
        record,
      );

      await dispatch(`doFetchLikes`, 
          payload.articleId,
          );
          await dispatch(`doFetchUserLike`, 
          {articleId: payload.articleId,userId: payload.userId}
          );
          await dispatch(`doFetchViewers`, 
          {articleId: payload.articleId,userId: payload.userId}
          );
      commit('FIND_ARTICLE_DETAILS_SUCCESS')


      // try {

      // } catch (error) {
      //   Errors.handle(error);
      //   commit('FIND_ERROR');
      //   commit('FIND_USER_ERROR');
      //   // routerAsync().push('/offer');
      // }
    }),
    // async doFindUser({ commit },userId) {
    //   try {
    //     console.log('in doFind User view store')
    //     commit('FIND_USER_STARTED');
    //     await PrescriptionsService.findUser(userId, async(doc) => {      
    //       console.log('createdBy',doc)      
    //       commit('FIND_USER_SUCCESS',  doc);
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_USER_ERROR');
    //     // routerAsync().push('/offer');
    //   }
    // },

  doFetchLikes:  firestoreAction(async ({bindFirestoreRef,commit} , id) => {

    commit('FIND_ARTICLE_DETAILS_STARTED')
    const db = firebase.firestore()
    const likes = db.collection(`article/${id}/likes`)
    

    // }
    await bindFirestoreRef(
      'likes',
      likes,
    );
    commit('FIND_ARTICLE_DETAILS_SUCCESS')
  }),
  doFetchUserLike: firestoreAction(async ({bindFirestoreRef,commit}, payload) => {
    commit('FIND_ARTICLE_DETAILS_STARTED')
    const db = firebase.firestore()
    // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
    const isLiked = db.collection(`article/${payload.articleId}/likes`).doc(payload.userId)
   
    
    
    // }
    await bindFirestoreRef(
      'isLiked',
      isLiked,
    );
    commit('FIND_ARTICLE_DETAILS_SUCCESS')
  }),

  doLike: firestoreAction(async ({bindFirestoreRef,commit,getters}, payload) => {
    commit('FIND_ARTICLE_DETAILS_STARTED')
    const db = firebase.firestore()
    const isLiked = db.collection(`article/${payload.articleId}/likes`).doc(payload.userId)
    
    // }
    await bindFirestoreRef(
      'isLiked',
      isLiked,
    );
    if(getters.isLiked === null) {
        await db
        .collection(`article/${payload.articleId}/likes`)
        .doc(payload.userId)
        .set({id: payload.userId})
        await firebase.firestore()
        .collection(`article/`)
        .doc(payload.articleId)
        .update({ 
          likesCount: firebase.firestore.FieldValue.increment(1) 
      })
    } else{
      await db.collection(`article/${payload.articleId}/likes`)
      .doc(payload.userId)
      .delete()

      await firebase.firestore()
        .collection(`article/`)
        .doc(payload.articleId)
        .update({ 
          likesCount: firebase.firestore.FieldValue.increment(-1) 
      })
    }

  }),

  doFetchViewers: firestoreAction(async ({bindFirestoreRef,commit,getters}, payload) => {
    commit('FIND_ARTICLE_DETAILS_STARTED')
    const db = firebase.firestore()
    // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
    const isAViewer = db.collection(`article/${payload.articleId}/views`).doc(payload.userId)
    
    
    
    // }
    await bindFirestoreRef(
      'viewer',
      isAViewer,
    );
    if(getters.viewer === null) {
      await db
        .collection(`article/${payload.articleId}/views`)
        .doc(payload.userId)
        .set({id: payload.userId})

        // await firebase.firestore()
        // .collection(`news/`)
        // .doc(payload.articleId)
      //   .update({ 
      //     viewersCount: firebase.firestore.FieldValue.increment(1) 
      // })

    }
    commit('FIND_ARTICLE_DETAILS_SUCCESS')
  }),

  },
  
};
