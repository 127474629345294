import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const culturalService = () =>
  import(
    '@/modules/cultural-service/components/cultural-service-list-page.vue'
  );
// const clinicViewPage = () =>
//   import('@/modules/clinic/components/clinic-view-page.vue');

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'cultural-service',
        path: 'cultural-service',
        component: culturalService,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.culturalService');
          next();
        },
      },
      // {
      //   name: 'clinic-view',
      //   path: 'clinic/:id',
      //   component: clinicViewPage,
      // },
    ],
  },
];
