import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-adminstration';

export class AdminstrationService {
  static async listDefault() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          administrationFindDefault {
            id
            description {
              en
              ar
            }
            presidentName {
              en
              ar
            }
            presidentPhoto {
              name
              publicUrl
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
    });
    return response.data.administrationFindDefault;
  }

  static async listDirectors() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
          $filter: DirectorFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          directorList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              order
              avatar {
                name
                publicUrl
              }
              name {
                en
                ar
              }
              jobTitle {
                en
                ar
              }
              facebookUrl
              instagramUrl
              twitterUrl

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'order',
        pagination: {
          limit: 20,
          sortBy: 'asc',
        },
      },
    });
    return response.data.directorList;
  }
}
