import firebase from 'firebase/app';
import 'firebase/auth';
import config from '@/config';
import 'firebase/firestore';

export default function firebaseInit() {
  const app = firebase.initializeApp(config.firebaseConfig);
  firebase.firestore().enablePersistence({synchronizeTabs:true})
  .catch(function(err) {
    if (err.code == 'failed-precondition') {
      console.log('failed-precondition',err)
    } else if (err.code == 'unimplemented') {
      console.log('unimplemented')
    }
  });
  app.auth();
}
