
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import moment from 'moment';
import { NewsModel } from '@/modules/news/news-model';
// import moment from 'moment';

export default {
  name: 'app-latest-news-card',
  props: ['record'],

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  methods: {
    i18n(code) {
      return i18n(code);
    },
    displayDate(date) {
      console.log(date);
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('ddd DD MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(
          row,
          'description_en',
        );
        // .split("\n")
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'description');
        // .split("\n")
        return val ? val : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      let date = NewsModel.presenter(row, fieldName);
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
  },
};
