
export default {
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar'
        },
        isDark() {
            return this.$q.dark.isActive
        }
    },
}
