const PrivacyPolicy = () =>
  import('@/modules/privacyAndTerms/components/privacy-policy.vue');
const TermsAndConditions = () =>
  import('@/modules/privacyAndTerms/components/terms-and-conditions.vue');
export default [
  {
    name: 'privacy-policy',
    path: '/:lang?/privacy-policy',
    exact: true,
    meta: { auth: false },
    component: PrivacyPolicy,
  },
  {
    name: 'terms-and-conditions',
    path: '/:lang?/terms-and-conditions',
    exact: true,
    meta: { auth: false },
    component: TermsAndConditions,
  },
];