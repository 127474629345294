import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import iam from '@/modules/iam/iam-module';
import auth2 from '@/modules/auth2/auth-module';
import settings from '@/modules/settings/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import news from '@/modules/news/news-module';
import branch from '@/modules/branch/branch-module';
import auction from '@/modules/auction/auction-module';
import article from '@/modules/article/article-module';
import restaurant from '@/modules/restaurant/restaurant-module';
import media from '@/modules/media/media-module';

import clinic from '@/modules/clinic/clinic-module';
import culturalService from '@/modules/cultural-service/cultural-service-module';
import telecom from '@/modules/telecom/telecom-module';
import currentBranch from '@/modules/currentBranch/currentBranch-module';

import parking from '@/modules/parking/parking-module';
import MedicalDiscounts from '@/modules/medical-discounts/medical-discounts-module';
import RestaurantsDiscounts from '@/modules/restaurants-discounts/restaurants-discounts-module';
import adminstration from '@/modules/adminstration/adminstration-module';

import error from '@/modules/404/404-module';

import live from '@/modules/live/live-module';
import library from '@/modules/library/library-module';
import kindergarten from '@/modules/kindergarten/kindergarten-module';
import privacyAndTerms from '@/modules/privacyAndTerms/privacyAndTerms-module';
import nonAthletes from '@/modules/non-athletes/non-athletes-module';
import { vuexfireMutations } from 'vuexfire';

const modules = {
  shared,
  home,
  settings,
  auth,
  iam,
  auditLog,
  layout,
  news,
  branch,
  article,
  privacyAndTerms,
  restaurant,
  media,
  clinic,
  culturalService,
  telecom,
  parking,
  MedicalDiscounts,
  adminstration,
  error,
  library,
  kindergarten,
  nonAthletes,
  auction,
  live,
  auth2,
  currentBranch,
  RestaurantsDiscounts,
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({
      modules: buildStores(),
      mutations: {
        // other mutations
        ...vuexfireMutations,
      },
    });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code

// import Vue from 'vue';
// import Vuex from 'vuex';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import Router from 'vue-router';
// import shared from '@/shared/shared-module';
// import auth from '@/modules/auth/auth-module';
// import layout from '@/modules/layout/layout-module';
// import home from '@/modules/home/home-module';
// import iam from '@/modules/iam/iam-module';
// import auth2 from '@/modules/auth2/auth-module';
// import settings from '@/modules/settings/settings-module';
// import auditLog from '@/modules/audit-log/audit-log-module';
// import news from '@/modules/news/news-module';
// import branch from '@/modules/branch/branch-module';
// import auction from '@/modules/auction/auction-module';
// import article from '@/modules/article/article-module';
// import restaurant from '@/modules/restaurant/restaurant-module';
// import media from '@/modules/media/media-module';
// import clinic from '@/modules/clinic/clinic-module';
// import culturalService from '@/modules/cultural-service/cultural-service-module';
// import telecom from '@/modules/telecom/telecom-module';

// import parking from '@/modules/parking/parking-module';
// import MedicalDiscounts from '@/modules/medical-discounts/medical-discounts-module';
// import adminstration from '@/modules/adminstration/adminstration-module';
// import live from '@/modules/live/live-module';
// import library from '@/modules/library/library-module';
// import kindergarten from '@/modules/kindergarten/kindergarten-module';
// import privacyAndTerms from '@/modules/privacyAndTerms/privacyAndTerms-module';
// import nonAthletes from '@/modules/non-athletes/non-athletes-module';
// import { vuexfireMutations } from 'vuexfire';
// const ComingSoon = () =>
//   import('@/modules/home/components/coming-soon2.vue');

// const modules = {
//   shared,
//   home,
//   settings,
//   auth,
//   iam,
//   auditLog,
//   layout,
//   news,
//   branch,
//   article,
//   privacyAndTerms,
//   restaurant,
//   media,
//   clinic,
//   culturalService,
//   telecom,
//   parking,
//   MedicalDiscounts,
//   adminstration,
//   library,
//   kindergarten,
//   nonAthletes,
//   auction,
//   live,
//   auth2,
// };

// // start - boilerplate code

// const exists = (el) => !!el;

// function setupComponentsFiltersDirectivesAndMixins() {
//   Object.keys(modules)
//     .map((key) => modules[key].components)
//     .filter(exists)
//     .forEach((components) => {
//       components.forEach((component) => {
//         Vue.component(component.name, component);
//       });
//     });

//   Object.keys(modules)
//     .map((key) => modules[key].filters)
//     .filter(exists)
//     .forEach((components) => {
//       components.forEach((filter) => {
//         Vue.filter(filter.name, filter.implementation);
//       });
//     });

//   Object.keys(modules)
//     .map((key) => modules[key].directives)
//     .filter(exists)
//     .forEach((directives) => {
//       directives.forEach((directive) => {
//         Vue.directive(
//           directive.name,
//           directive.implementation,
//         );
//       });
//     });

//   Object.keys(modules)
//     .map((key) => modules[key].mixins)
//     .filter(exists)
//     .forEach((mixins) => {
//       mixins.forEach((mixin) => {
//         Vue.mixin(mixin);
//       });
//     });
// }
// // const tempRoute = [
// //   {
// //     path: '/:lang?',
// //     exact: true,
// //     children: [
// //       {
// //         name: 'coming-soon',
// //         path: 'coming-soon',
// //         component: ComingSoon,
// //         // exact: true,
// //         meta: { auth: false },
// //         beforeEnter: (to, from, next) => {
// //           next();
// //         },
// //       },
// //     ],
// //   },
// // ];
// const routes = [
//   // ...Object.keys(modules)
//   //   .filter((key) => !!modules[key].routes)
//   //   .map((key) => modules[key].routes)
//   //   .reduce((a, b) => a.concat(b), []),
//   {
//     path: '/:lang?/',
//     component: ComingSoon,
//     meta: { auth: false },
//   },
//   { path: '*', redirect: '/:lang?/' },
// ];

// let router = null;

// const routerAsync = () => {
//   if (!router) {
//     router = new Router({
//       mode: 'history',
//       routes,
//       scrollBehavior() {
//         return { x: 0, y: 0 };
//       },
//     });

//     router.beforeEach((to, from, next) => {
//       if (to.name) {
//         ProgressBar.start();
//       }

//       next();
//     });

//     router.afterEach(() => {
//       ProgressBar.done();
//     });
//   }

//   return router;
// };

// const buildStores = () => {
//   const output = {};

//   Object.keys(modules)
//     .filter((key) => !!modules[key].store)
//     .forEach((key) => {
//       output[key] = modules[key].store;
//     });

//   return output;
// };

// let store = null;

// const storeAsync = () => {
//   if (!store) {
//     store = new Vuex.Store({
//       modules: buildStores(),
//       mutations: {
//         // other mutations
//         ...vuexfireMutations,
//       },
//     });
//   }

//   return store;
// };

// export {
//   setupComponentsFiltersDirectivesAndMixins,
//   routerAsync,
//   storeAsync,
// };

// end - boilerplate code
