import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const AdminListPage = () =>
  import('@/modules/adminstration/components/adminstration-list-page.vue');

export default [{
  path: '/:lang?',
  exact: true,
  component: Layout,
  children: [{
      name: 'adminstration',
      path: 'adminstration',
      component: AdminListPage,
      // exact: true,
      meta: { auth: false },
      beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('menu.adminstration') 
        next();
      },
    }
  ]
}];
