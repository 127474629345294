import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const MediaViewPage = () =>
  import('@/modules/media/components/media-view-page.vue');
const MediaAlbumPage = () => import('@/modules/media/components/media-album-page.vue')

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'media',
        path: 'media',
        component: MediaViewPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') + ' | ' + i18n('menu.media');
          next();
        },
      },
      {
        name: 'album-view',
        path: 'media/:id',
        component: MediaAlbumPage,
        props: true
      }
    ],
  },
];
