
export default {
    data() {
        return {

        }
    }
    , props: ['info'],
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar';
        },
    }
}
