import Roles from '@/security/roles';
const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      // ============================================================================================================ //
      //                                                   IAM
      // ============================================================================================================ //
      //#region [ IAM ]
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.iamSecurityReviewer,
          roles.editor,
          roles.viewer,
        ],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,

          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   AUDITLOG
      // ============================================================================================================ //
      //#region [ AUDITLOG ]
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.auditLogViewer, roles.viewer],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.owner],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   PRODUCTS
      // ============================================================================================================ //
      //#region [ PRODUCTS ]
      productImport: {
        id: 'productImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.productEditor,
        ],
      },
      productExport: {
        id: 'productExport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.productEditor,
        ],
      },
      productCreate: {
        id: 'productCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.productEditor,
        ],
        allowedStorageFolders: ['product'],
      },
      productEdit: {
        id: 'productEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.productEditor,
        ],
        allowedStorageFolders: ['product'],
      },
      productDestroy: {
        id: 'productDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.productEditor,
        ],
        allowedStorageFolders: ['product'],
      },
      productRead: {
        id: 'productRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.productEditor,
          roles.productViewer,
        ],
      },
      productAutocomplete: {
        id: 'productAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.productEditor,
          roles.productViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   SPARE PARTS
      // ============================================================================================================ //
      //#region [ SPARE PARTS ]
      sparePartImport: {
        id: 'sparePartImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sparePartEditor,
        ],
      },
      sparePartExport: {
        id: 'sparePartExport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sparePartEditor,
        ],
      },
      sparePartCreate: {
        id: 'sparePartCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sparePartEditor,
        ],
        allowedStorageFolders: ['sparePart'],
      },
      sparePartEdit: {
        id: 'sparePartEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sparePartEditor,
        ],
        allowedStorageFolders: ['sparePart'],
      },
      sparePartDestroy: {
        id: 'sparePartDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sparePartEditor,
        ],
        allowedStorageFolders: ['sparePart'],
      },
      sparePartRead: {
        id: 'sparePartRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sparePartEditor,
          roles.sparePartViewer,
        ],
      },
      sparePartAutocomplete: {
        id: 'sparePartAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sparePartEditor,
          roles.sparePartViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   ACCESSORY
      // ============================================================================================================ //
      //#region [ ACCESSORY ]
      accessoryImport: {
        id: 'accessoryImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.accessoryEditor,
        ],
      },
      accessoryExport: {
        id: 'accessoryExport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.accessoryEditor,
        ],
      },
      accessoryCreate: {
        id: 'accessoryCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.accessoryEditor,
        ],
        allowedStorageFolders: ['accessory'],
      },
      accessoryEdit: {
        id: 'accessoryEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.accessoryEditor,
        ],
        allowedStorageFolders: ['accessory'],
      },
      accessoryDestroy: {
        id: 'accessoryDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.accessoryEditor,
        ],
        allowedStorageFolders: ['accessory'],
      },
      accessoryRead: {
        id: 'accessoryRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.accessoryEditor,
          roles.accessoryViewer,
        ],
      },
      accessoryAutocomplete: {
        id: 'accessoryAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.accessoryEditor,
          roles.accessoryViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   SELLER
      // ============================================================================================================ //
      //#region [ SELLER ]
      sellerImport: {
        id: 'sellerImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sellerEditor,
        ],
      },
      sellerCreate: {
        id: 'sellerCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sellerEditor,
        ],
        allowedStorageFolders: ['seller'],
      },
      sellerEdit: {
        id: 'sellerEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sellerEditor,
        ],
        allowedStorageFolders: ['seller'],
      },
      sellerDestroy: {
        id: 'sellerDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.sellerEditor,
        ],
        allowedStorageFolders: ['seller'],
      },
      sellerRead: {
        id: 'sellerRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sellerEditor,
          roles.sellerViewer,
        ],
      },
      sellerAutocomplete: {
        id: 'sellerAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sellerEditor,
          roles.sellerViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   BRANCH
      // ============================================================================================================ //
      //#region [ BRANCH ]
      branchImport: {
        id: 'branchImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
      },
      branchCreate: {
        id: 'branchCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchEdit: {
        id: 'branchEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchDestroy: {
        id: 'branchDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchRead: {
        id: 'branchRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.branchEditor,
          roles.branchViewer,
        ],
      },
      branchAutocomplete: {
        id: 'branchAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.branchEditor,
          roles.branchViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion


      // ============================================================================================================ //
      //                                                   IMPORTER
      // ============================================================================================================ //
      //#region [ IMPORTER ]
      importerImport: {
        id: 'importerImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.importerEditor,
        ],
      },
      importerCreate: {
        id: 'importerCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.importerEditor,
        ],
        allowedStorageFolders: ['importer'],
      },
      importerEdit: {
        id: 'importerEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.importerEditor,
        ],
        allowedStorageFolders: ['importer'],
      },
      importerDestroy: {
        id: 'importerDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.importerEditor,
        ],
        allowedStorageFolders: ['importer'],
      },
      importerRead: {
        id: 'importerRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.importerEditor,
          roles.importerViewer,
        ],
      },
      importerAutocomplete: {
        id: 'importerAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.importerEditor,
          roles.importerViewer,

        ],
      },
      //#endregion
       
      // ============================================================================================================ //
      //                                                   EXPORT
      // ============================================================================================================ //
      //#region [ EXPORT ]
      exportImport: {
        id: 'exportImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.exportEditor,
        ],
      },
      exportCreate: {
        id: 'exportCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.exportEditor,
        ],
        allowedStorageFolders: ['export'],
      },
      exportEdit: {
        id: 'exportEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.exportEditor,
        ],
        allowedStorageFolders: ['export'],
      },
      exportDestroy: {
        id: 'exportDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.exportEditor,
        ],
        allowedStorageFolders: ['export'],
      },
      exportRead: {
        id: 'exportRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.exportEditor,
          roles.exportViewer,
        ],
      },
      exportAutocomplete: {
        id: 'exportAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.exportEditor,
          roles.exportViewer,

        ],
      },
      //#endregion
       
      // ============================================================================================================ //
      //                                                   ACTIVATION
      // ============================================================================================================ //
      //#region [ ACTIVATION ]
      activationImport: {
        id: 'activationImport',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.activationEditor,
        ],
      },
      activationCreate: {
        id: 'activationCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.activationEditor,
        ],
        allowedStorageFolders: ['activation'],
      },
      activationEdit: {
        id: 'activationEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.activationEditor,
        ],
        allowedStorageFolders: ['activation'],
      },
      activationDestroy: {
        id: 'activationDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.entityEditor,
          roles.activationEditor,
        ],
        allowedStorageFolders: ['activation'],
      },
      activationRead: {
        id: 'activationRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      activationAutocomplete: {
        id: 'activationAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.activationEditor,
          roles.activationViewer,

        ],
      },
      //#endregion
          
      // ============================================================================================================ //
      //                                                   CUSTOMER
      // ============================================================================================================ //
      //#region [ CUSTOMER ]
      customerImport: {
        id: 'customerImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.customerEditor,
        ],
      },
      customerCreate: {
        id: 'customerCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.customerEditor,
        ],
        allowedStorageFolders: ['customer'],
      },
      customerEdit: {
        id: 'customerEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.customerEditor,
        ],
        allowedStorageFolders: ['customer'],
      },
      customerDestroy: {
        id: 'customerDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.customerEditor,
        ],
        allowedStorageFolders: ['customer'],
      },
      customerRead: {
        id: 'customerRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerEditor,
          roles.customerViewer,
        ],
      },
      customerAutocomplete: {
        id: 'customerAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerEditor,
          roles.customerViewer,
          roles.activationEditor,
          roles.activationViewer,
        ],
      },
      //#endregion

    
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
