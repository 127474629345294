
import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';
export default {
  data() {
    return {
      isSubMenuActive: false,
      Csports: [
        {
          id: 'QhZVvNxpANqfkbQPfEIL',
          name: {
            en: 'football juniors',
            ar: 'ناشئين كرة القدم',
          },
          index: 2,
        },
        {
          index: 3,
          id: '9hdXRPI6WPchkXRnnOe3',
          name: {
            en: 'basketball',
            ar: 'كرة السلة',
          },
        },
        {
          id: 'WUsRbTWLK3HtGepEuIAj',
          index: 4,
          name: {
            ar: 'كرة اليد',
            en: 'handball',
          },
        },
        {
          index: 5,
          name: {
            ar: 'العاب المضرب',
            en: 'racket games',
          },
          id: 'AvAtboshxN01WXAvcyu7',
          subCategories: [
            {
              id: '7vWngCyjZiadyQRrHoBU',
              name: {
                ar: 'الأسكواش',
                en: 'squash',
              },
            },
            {
              id: 'LECWE0NOedHf8VoqG0t9',
              name: {
                en: 'ping pong table',
                ar: 'تنس الطاولة',
              },
            },
            {
              name: {
                ar: 'التنس',
                en: 'tennis',
              },
              id: 'OH7wpZhtgnltKCIZ7XGR',
            },
          ],
        },
        {
          index: 6,
          id: 'KlvSvMiTY2OEp3CoUfW6',
          name: {
            ar: 'السباحة و ألعاب الماء',
            en: 'swimming and water games',
          },
        },
        {
          id: 'W636vSrVESBd0dXZOogJ',
          index: 7,
          name: {
            en: 'volleyball',
            ar: 'الكرة الطائرة',
          },
        },
        {
          name: {
            ar: 'الهوكي',
            en: 'hockey',
          },
          id: 'WgqocKZzXTtZ9rEFQC7M',
          index: 8,
        },
        {
          id: 'WjI0hiEXs3CIyMVerOrC',
          name: {
            ar: 'ألعاب الترفيه',
            en: 'Entertainment Games',
          },
          index: 9,
          subCategories: [
            {
              name: {
                ar: 'الشطرنج',
                en: 'chess',
              },
              id: '8Qt4ZsgWk4r90uqrQc4f',
            },
            {
              name: {
                en: 'croquet',
                ar: 'الكروكيه',
              },
            },
            {
              id: 'HIhrcPRhLGrI12l4hq0D',
              name: {
                ar: 'البولينج',
                en: 'bowling',
              },
            },
          ],
        },
        {
          index: 10,
          id: 'WpnZf3lrY75tyflgIV0n',
          name: {
            en: 'athletics',
            ar: 'العاب القوى',
          },
        },
        {
          name: {
            ar: 'الجمباز',
            en: 'Gymnastics',
          },
          id: 'XTCMETRrXCxUS8YzzWm3',
          index: 11,
        },
        {
          name: {
            ar: 'الفريق الأول لكرة القدم',
            en: 'football first team',
          },
          id: 'o5mNGYyPJJQX7bjQFsyT',
          index: 12,
        },
        {
          index: 13,
          id: 'sC3uCOrpVH8QzMrNc1cU',
          name: {
            en: 'self defense games',
            ar: 'العاب الدفاع عن النفس',
          },
          subCategories: [
            {
              name: {
                ar: 'التايكوندو',
                en: 'taekwondo',
              },
              id: 'TENH4tTTnawoxaKa229O',
            },
            {
              id: 'Z5WNBoMKpRgPpPyXzEGJ',
              name: {
                en: 'karate',
                ar: 'الكاراتيه',
              },
            },
            {
              name: {
                en: 'judo',
                ar: 'الجودو',
              },
              id: 'oVjodM6apyvKiYL2aYTM',
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleSubMenu(e) {
      console.log(e.target);
      e.target.classList.toggle('showmenu');
    },

    setShowMenu(e) {
      this.$el
        .querySelector(`[id='${e.target.id}'] + section`)
        .classList.toggle('active');

      // this.$el.querySelector(`#${e.target.id} > .sub-dropdown`)
    },
    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
    goToRoute(name) {
      console.log(name);
      // debugger
      this.$router.push({
        name,
      });
      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  computed: {
    ...mapGetters({
      sports: 'layout/sports',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
};
