
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-sponsor',
  data() {
    return {
      dis: false,
      finished: false,
      isOpen: false,
      data: {
        type: 'ads',
      },
      error: false,
      errorMessage: '',
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      rows: 'layout/rows',
      loading: 'news/list/loading',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    isDark() {
      return this.$q.dark.isActive;
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'layout/doFetch',
      doCreateContact: 'layout/doCreateContact',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    isFormValid() {
      for (var key in this.data) {
        var value = this.data[key];
        for (var groupKey in value) {
          var value2 = value[groupKey];
          if (value2.length == 0) {
            this.errorMessage =
              'Please Fill all the Fields';
            return false;
          }
        }
      }
      if (!this.validEmail(this.data.email)) {
        this.errorMessage = 'Please enter a valid Email';
        return false;
      }
      console.log(this.data);
      return true;
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    async doSubmit() {
      this.dis = true;
      if (!this.isFormValid()) {
        this.error = true;
        this.dis = false;
      } else {
        this.error = false;
        await this.doCreateContact(this.data);
        this.finished = true;
        this.data = {};
        this.dis = false;
      }
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
  },
  async created() {
    if (this.isMobile) {
      this.collapseMenu();
    }
    await this.doFetch();
    console.log(this.rows);
  },
};
