
import gsap from 'gsap';
import { mapGetters, mapActions } from 'vuex';
export default {
  mounted() {
    const t1 = gsap.timeline({
      defaults: { ease: 'power1.out' },
    });
    this.isRTL
      ? t1.fromTo(
          '.text',
          { x: '-100%' },
          { x: '0%', duration: 0.35, stagger: 0.1 },
        )
      : t1.fromTo(
          '.text',
          { x: '100%' },
          { x: '0%', duration: 0.35, stagger: 0.1 },
        );
  },
  methods: {
    goToRoute(name) {
      // debugger
      this.$router.push({
        name,
      });
      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      changeBranch: 'branch/view/changeBranch',
      current: 'branch/view/branch',
      doFetch: 'branch/view/doFetch',
    }),
    changeBranchh(id) {
      this.changeBranch(id);
      localStorage.setItem('current_branch', id);
      console.log(this.currentBranch);
      // location.reload();
    },
  },
  computed: {
    ...mapGetters({
      currentBranch: 'branch/view/branch',
      rows: 'branch/view/rows',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  async created() {
    this.doFetch();
    console.log(this.rows);
  },
  // created() {
  //     const t1 = gsap.timeline({ defaults: { ease: "power1.out" } })
  //     t1.fromTo(".text", { x: "100%" }, { x: "0%", duration: 0.5, stagger: 0.1 })
  // }
};
