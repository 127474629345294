
export default {
  name: 'app-social-account-card',
  props: ['type', 'to', 'img', 'title'],

  data() {
    return {
      icon: null,
      name: this.type.toLowerCase(),
      unknownName: false,
      icon_facebook: 'fab fa-facebook-f',
      icon_instagram: 'fab fa-instagram-square',
      icon_twitter: 'fab fa-twitter',
      icon_youtube: 'fab fa-youtube',
      icon_phone: 'fas fa-phone-alt',
      icon_whatsapp: 'fab fa-whatsapp',
      icon_whatsapp_square: 'fab fa-whatsapp-square',
      icon_tiktok: 'fab fa-tiktok',
      icon_email: 'fas fa-envelope',
      icon_vimeo: 'fab fa-vimeo-v',
      icon_linkedin: 'fab fa-linkedin',
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  created() {
    if (this.name.includes('facebook')) {
      this.icon = this.icon_facebook;
      this.name = 'facebook';
    } else if (this.name.includes('instagram')) {
      this.icon = this.icon_instagram;
      this.name = 'instagram';
    } else if (this.name.includes('twitter')) {
      this.icon = this.icon_twitter;
      this.name = 'twitter';
    } else if (this.name.includes('youtube')) {
      this.icon = this.icon_youtube;
      this.name = 'youtube';
    } else if (this.name.includes('whatsapp')) {
      this.icon = this.icon_whatsapp;
      this.name = 'whatsapp';
    } else if (this.name.includes('tiktok')) {
      this.icon = this.icon_tiktok;
      this.name = 'tiktok';
    } else if (this.name.includes('email')) {
      this.icon = this.icon_email;
      this.name = 'email';
    } else if (this.name.includes('vimeo')) {
      this.icon = this.icon_vimeo;
      this.name = 'vimeo';
    } else if (this.name.includes('linkedin')) {
      this.icon = this.icon_linkedin;
      this.name = 'linkedin';
    } else {
      this.unknownName = true;
    }
  },
};
