
export default {
  name: 'app-media-photo-card',
  props: ['src', 'to'],

  data() {
    return {

    }
  },
  computed: {

  },
};
