import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-cultural-service';

export class CulturalService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query CULTURAL_LIST(
          $filter: CulturalFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          culturalList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              banner
              description {
                en
                ar
              }

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
    });
    return response.data.culturalList;
  }
}
