import firebase from 'firebase/app';
import 'firebase/storage';
import * as uuid from 'uuid/v4';
import { i18n } from '@/i18n';
import filesize from 'filesize';
// const webp = require('webp-converter');
 
// this will grant 755 permission to webp executables
// webp.grant_permission();

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}

export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return;
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error(i18n('fileUploader.image'));
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error(
        i18n('fileUploader.size', filesize(schema.size)),
      );
    }

    const extension = extractExtensionFrom(file.name);

    if (
      schema.formats &&
      !schema.formats.includes(extension)
    ) {
      throw new Error(
        i18n(
          'fileUploader.formats',
          schema.formats.join('/'),
        ),
      );
    }
  }

  static uploadFromRequest(path, request, schema) {
    try {
      FileUploader.validate(request.file, schema);
    } catch (error) {
      request.onError(error);
      return;
    }
    

    const ref = firebase.storage().ref();
    const extension = extractExtensionFrom(
      request.file.name,
    );
    const id = uuid();
    const fullPath = `${path}/${id}.${extension}`;
    const task = ref.child(fullPath).put(request.file);


    console.log(request);
    // webp.grant_permission();
    // const result = webp.cwebp(request.file,`${path}/${id}.webp`,"-q 80");
    // result.then((response) => {
    //   console.log(response);
    // });

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const percent =
          (snapshot.bytesTransferred /
            snapshot.totalBytes) * 100;

        setTimeout(() => {
          request.onProgress({ percent });
        }, 0);
      },
      (error) => {
        request.onError(error);
      },
      () => {
        task.snapshot.ref
          .getDownloadURL()
          .then((url) => {
            request.onSuccess({
              id: id,
              name: request.file.name,
              sizeInBytes: task.snapshot.totalBytes,
              privateUrl: fullPath,
              publicUrl: url,
              new: true,
            });
          })
          .catch((error) => {
            request.onError(error);
          });
      },
    );
  }

    //   let refs = {};
    // refs.imagePreviews = document.querySelector('#previews');
    // refs.fileSelector = document.querySelector('input[type=file]');

    // function addImageBox(container) {
    //   let imageBox = document.createElement("div");
    //   let progressBox = document.createElement("progress");
    //   imageBox.appendChild(progressBox);
    //   container.appendChild(imageBox);
      
    //   return imageBox;
    // }

    // static processFile(file) {
    //   if (!file) {
    //     return;
    //   }
    //   console.log(file);

    //   let imageBox = addImageBox(refs.imagePreviews);

    //   // Load the data into an image
    //   new Promise(function (resolve, reject) {
    //     let rawImage = new Image();

    //     rawImage.addEventListener("load", function () {
    //       resolve(rawImage);
    //     });

    //     rawImage.src = URL.createObjectURL(file);
    //   })
    //   .then(function (rawImage) {
    //     // Convert image to webp ObjectURL via a canvas blob
    //     return new Promise(function (resolve, reject) {
    //       let canvas = document.createElement('canvas');
    //       let ctx = canvas.getContext("2d");

    //       canvas.width = rawImage.width;
    //       canvas.height = rawImage.height;
    //       ctx.drawImage(rawImage, 0, 0);

    //       canvas.toBlob(function (blob) {
    //         resolve(URL.createObjectURL(blob));
    //       }, "image/webp");
    //     });
    //   })
    //   .then(function (imageURL) {
    //     // Load image for display on the page
    //     return new Promise(function (resolve, reject) {
    //       let scaledImg = new Image();

    //       scaledImg.addEventListener("load", function () {
    //         resolve({imageURL, scaledImg});
    //       });

    //       scaledImg.setAttribute("src", imageURL);
    //     });
    //   })
    //   .then(function (data) {
    //     // Inject into the DOM
    //     let imageLink = document.createElement("a");

    //     imageLink.setAttribute("href", data.imageURL);
    //     imageLink.setAttribute('download', `${file.name}.webp`);
    //     imageLink.appendChild(data.scaledImg);

    //     imageBox.innerHTML = "";
    //     imageBox.appendChild(imageLink);
    //   });
    // }

    // static processFiles(files) {
    //   for (let file of files) {
    //     processFile(file);
    //   }
    // }

    // static fileSelectorChanged() {
    //   processFiles(refs.fileSelector.files);
    //   refs.fileSelector.value = "";
    // }

    // refs.fileSelector.addEventListener("change", fileSelectorChanged);

    // Set up Drag and Drop
    // static dragenter(e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    // }

    // static dragover(e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    // }

    // static drop(callback, e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   callback(e.dataTransfer.files);
    // }

    // static setDragDrop(area, callback) {
    //   area.addEventListener("dragenter", dragenter, false);
    //   area.addEventListener("dragover", dragover, false);
    //   area.addEventListener("drop", function (e) { drop(callback, e); }, false);
    // }
    // setDragDrop(document.documentElement, processFiles);
}
