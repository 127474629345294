import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const KindergartenListPage = () =>
  import(
    '@/modules/kindergarten/components/kindergarten-list-page.vue'
  );

const kindergartenViewPage = () =>
  import(
    '@/modules/kindergarten/components/kindergarten-view-page.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'kindergarten',
        path: 'kindergarten',
        component: KindergartenListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.kindergarten');
          next();
        },
      },
      {
        name: 'kindergarten-view',
        path: 'kindergarten/:id',
        component: kindergartenViewPage,
      },
    ],
  },
];
