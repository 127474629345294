const SplashScreenPage = () =>
  import('@/modules/auth2/components/splash-screen.vue');
// import signup from './components/signup.vue';

const SignUp = () =>
  import('@/modules/auth2/components/signup.vue');
// import Layout from '@/modules/layout/components/layout.vue';
// import { i18n } from '@/vueI18n';

// const LibraryViewPage = () =>
//   import(
//     '@/modules/library/components/library-view-page.vue'
//   );

export default [
  {
    // path: '',
    path: '/:lang?/auth2',
    exact: true,
    component: SplashScreenPage,
    // children: [
    //   {
    //     name: 'signup-view',
    //     path: 'signup',
    //     component: SignUp,
    //     exact: true,
    //   },
    // ],
  },

  {
    // path: '',
    path: '/:lang?/signup',
    exact: true,
    component: SignUp,
    // children: [
    //   {
    //     name: 'signup-view',
    //     path: 'signup',
    //     component: SignUp,
    //     exact: true,
    //   },
    // ],
  },
];
