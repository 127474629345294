import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-discounts';

export class RestaurantsDiscounts {
  static async list(branchId, type, action, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query DISCOUNTS_LIST(
          $filter: DiscountsFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          discountsList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              addresses {
                en
                ar
              }
              pohoneNumbers
              offers
              banner
              logo
              logo_thumbnail
              type
              branchId

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId, type: type },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: action,
          page: currentPage,
        },
      },
    });
    return response.data.discountsList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query DISCOUNTS_FIND($id: String!) {
          discountsFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            addresses {
              en
              ar
            }
            pohoneNumbers
            offers
            banner
            logo
            logo_thumbnail
            type

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.discountsFind;
  }
}
