
import { mapGetters } from 'vuex';

export default {
  name: 'app-match-card',
  props: {
    match: {
      matchType: {
        type: String,
        required: true,
      },
      matchDate: {
        type: String,
        required: true,
      },
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      icon_football: '/images/icons/ic-football.webp',
      icon_basketball: '/images/icons/ic-basketball.webp',
      icon_yellow_award: '/images/icons/yellow_award.svg',
      icon_red_award: '/images/icons/red_award.svg',
      diffDays: null
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    icon() {
      let icon = null;
      switch (this.match.matchType) {
        case 'basketball':
          icon = this.icon_basketball;
          break;
        default:
          icon = this.icon_football;
          break;
      }
      return icon;
    },
    icon_award() {
      let icon = null;
      switch (this.match.matchType) {
        case 'basketball':
          icon = this.icon_red_award;
          break;
        default:
          icon = this.icon_yellow_award;
          break;
      }
      return icon;
    },
    date() {
      return ''
    }
  },
  created() {
    var today = new Date();
    // console.log('today',today);
    // console.log('match date', this.match.matchDate);

    const matchDate = new Date(this.match.matchDate);
    // const date2 = new Date('12/15/2010');
    const diffTime = Math.abs(matchDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.diffDays = diffDays
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");

    // var dd = String(today. getDate()). padStart(2, '0');
    // var mm = String(today. getMonth() + 1). padStart(2, '0'); //January is 0!
    // var yyyy = today. getFullYear();
  }
};
