
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import moment from 'moment'

export default {
  name: 'app-countdown',
  props: ['countDownDate'],
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    }
  },

  created() {

    // Set the date we're counting down to
    let countDownDate = new Date(this.countDownDate).getTime();

    // Update the count down every 1 second
    let x = setInterval(() => {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);



      // Output the result in an element with id="demo"

      // If the count down is over, write some text 
      if (distance < 0) {
        // this.doFindNextDate()
        clearInterval(x);
        document.getElementById("countdown").innerHTML = "EXPIRED";
        document.getElementById("countdown").classList.add("next")
      }
    }, 1000);
  },

  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },
  methods: {
    i18n(code) {
      return i18n(code)
    },
    displayDate(date) {
      return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
    doFindNextDate() {
      this.$emit('nextMatchDate')
    },
  },
  watch: {
    // countDownDate() {
    //   // Set the date we're counting down to
    //   let countDownDate = new Date(this.countDownDate).getTime();

    //   // Update the count down every 1 second
    //   let x = setInterval(function() {
    //     // Get today's date and time
    //     let now = new Date().getTime();

    //     // Find the distance between now and the count down date
    //     let distance = countDownDate - now;

    //     // Time calculations for days, hours, minutes and seconds
    //     let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //     // Output the result in an element with id="demo"
    //     document.getElementById("days").innerHTML = days 
    //     document.getElementById("hours").innerHTML = hours 
    //     document.getElementById("minutes").innerHTML = minutes 
    //     document.getElementById("seconds").innerHTML = seconds 

    //     // If the count down is over, write some text 
    //     if (distance < 0) {
    //       clearInterval(x);
    //       document.getElementById("countdown").innerHTML = "EXPIRED";
    //       document.getElementById("countdown").classList.add("next")
    //     }
    //   }, 1000);
    // },
  },
};
