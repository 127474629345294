// import 'nprogress/nprogress.css';
// import NProgress from 'nprogress';
// NProgress.configure({ showSpinner: false });
import { LoadingBar } from 'quasar'

export default class ProgressBar {
  static start() {
    // NProgress.start();
    LoadingBar.start();
  }

  static done() {
    // NProgress.done();
    LoadingBar.stop();
  }
}
