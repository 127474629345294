import Layout from '@/modules/layout/components/layout.vue';
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ArticleListPage = () =>
  import('@/modules/article/components/article-list-page.vue');
const ArticleViewPage = () =>
  import('@/modules/article/components/article-view-page.vue');
export default [{
  path: '/:lang?',
  exact: true,
  component: Layout,
  children: [
    {
      name: 'article',
      path: 'article',
      component: ArticleListPage,
      meta: { auth: false },
      beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('menu.article') 
        next();
      },
    },
    {
      name: 'article-view',
      path: 'article/:id',
      component: ArticleViewPage,
    },

  ]
}];