
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import moment from 'moment'

export default {
  name: 'app-media-video-card',
  props: ['to', 'src', 'desc', 'date'],

  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },
  methods: {
    i18n(code) {
      return i18n(code)
    },
    displayDate(date) {
      return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    }
  },
};
