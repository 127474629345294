import { AdminstrationService } from '@/modules/adminstration/adminstration-service';
import Errors from '@/shared/error/errors';
const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    directors: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    adminstration: [],
    currentUserPrescriptions: [],
    prescriptionsWithCreators: [],
    user: {},
    mountedTable: false,
    currentRoute: undefined,
    table: null,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    directors: (state) => state.directors || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    adminstration: (state) => {
      return state.adminstration.sort((a, b) => {
        var keyA = a.date,
          keyB = b.date;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    },
    mountedTable: (state) => state.mountedTable,
    currentRoute: (state) => state.currentRoute,
    user: (state) => state.user,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_DIRECTORS_SUCCESS(state, payload) {
      state.loading = false;
      state.directors = payload.rows;
    },
    FETCH_DIRECTORS_STARTED(state) {
      state.loading = true;
    },

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },

    APPEND_TO_LIST(state, payload) {
      state.rows.push(payload);
    },
  },

  actions: {
    async doFetchDefault({ commit }) {
      try {
        commit('FETCH_STARTED');
        const response =
          await AdminstrationService.listDefault();
        console.log(response);
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchDirectors({ commit }) {
      try {
        commit('FETCH_DIRECTORS_STARTED');
        const response =
          await AdminstrationService.listDirectors();
        console.log(response);
        commit('FETCH_DIRECTORS_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};
