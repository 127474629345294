// import Layout from '@/modules/layout/components/layout.vue';
// import AuthLayout from '@/modules/auth/components/auth-layout.vue';
// import { i18n } from '@/i18n';

const SplashScreenPage = () =>
  import(
    '@/modules/auth/components/splash-screen-page.vue'
  );
// const SigninPage = () =>
//   import('@/modules/auth/components/signin-page.vue');
// const SignupPage = () =>
//   import('@/modules/auth/components/signup-page.vue');
// const ForgotPasswordPage = () =>
//   import(
//     '@/modules/auth/components/forgot-password-page.vue'
//   );
// const EmailUnverifiedPage = () =>
//   import(
//     '@/modules/auth/components/email-unverified-page.vue'
//   );
// const EmptyPermissionsPage = () =>
//   import(
//     '@/modules/auth/components/empty-permissions-page.vue'
//   );
// const ProfileFormPage = () =>
//   import('@/modules/auth/components/profile-form-page.vue');

export default [
  {
    name: 'splashScreen',
    path: '/auth',
    component: SplashScreenPage,
    meta: {
      unauth: true,
    },
  },
  // {
  //   name: '',
  //   path: '',
  //   component: AuthLayout,
  //   children: [
  //     {
  //       name: 'signin',
  //       path: '/auth/signin',
  //       component: SigninPage,
  //       meta: {
  //         unauth: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.signin')
  //         next();
  //       },
  //     },
  //     {
  //       name: 'signup',
  //       path: '/auth/signup',
  //       component: SignupPage,
  //       meta: {
  //         unauth: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.signup')
  //         next();
  //       },
  //     },
  //     {
  //       name: 'forgotPassword',
  //       path: '/auth/forgot-password',
  //       component: ForgotPasswordPage,
  //       meta: {
  //         unauth: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.forgotPasswordTitle')
  //         next();
  //       },
  //     },
  //     {
  //       name: 'emailUnverified',
  //       path: '/auth/email-unverified',
  //       component: EmailUnverifiedPage,
  //       meta: {
  //         auth: true,
  //         emailAlreadyVerified: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.emailUnverified.verify')
  //         next();
  //       },
  //     },
  //     {
  //       name: 'emptyPermissions',
  //       path: '/auth/empty-permissions',
  //       component: EmptyPermissionsPage,
  //       meta: {
  //         auth: true,
  //         notEmptyPermissions: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.emptyPermissions.title')
  //         next();
  //       },
  //     },
  //   ],
  // },
  // {
  //   name: '',
  //   path: '',
  //   component: Layout,
  //   meta: {
  //     auth: true
  //   },
  //   children: [
  //     {
  //       name: 'editProfile',
  //       path: '/auth/edit-profile',
  //       component: ProfileFormPage,
  //       meta: {
  //         auth: true
  //       },
  //       beforeEnter: (to, from, next) => {
  //         document.title =
  //           i18n('app.title') +
  //           ' | ' +
  //           i18n('auth.profile.title')
  //         next();
  //       },
  //     },
  //   ],
  // }
];
