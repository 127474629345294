
export default {
  setup() {
    function toggleTheme() {
      localStorage.setItem('mode', !this.$q.dark.isActive);
      this.$q.dark.set(!this.$q.dark.isActive);
    }

    return {
      toggleTheme,
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  }
};
