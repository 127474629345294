import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-live';

export class LiveService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query LIVE_LIST(
          $filter: LiveFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          liveList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              html

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'desc',
        },
      },
    });
    return response.data.liveList;
  }
}
