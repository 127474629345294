import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const SettingsPage = () =>
  import('@/modules/settings/components/settings-page.vue');
const BarcodePage = () =>
  import('@/modules/settings/components/barcode-page.vue');

export default [
  {
    path: '',
    component: Layout,
    children: [
      {
        name: 'settings',
        path: '/settings',
        component: SettingsPage,
        exact: true,
        meta: {
          auth: true,
          permission: Permissions.values.settingsEdit,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.setting') 
          next();
        },
      },
      {
        name: 'barcode',
        path: '/barcode',
        component: BarcodePage,
        exact: true,
        meta: {
          auth: true,
          permission: Permissions.values.settingsEdit,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.barcode') 
          next();
        },
      },
    ],
  },
];
