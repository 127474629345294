
import gsap from 'gsap';

export default {
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  mounted() {
    const t1 = gsap.timeline({
      defaults: { ease: 'power1.out' },
    });
    this.isRTL
      ? t1.fromTo(
          '.text',
          { x: '-100%', delay: 0.3 },
          { x: '0%', duration: 0.35, stagger: 0.1 },
        )
      : t1.fromTo(
          '.text',
          { x: '100%', delay: 0.3 },
          { x: '0%', duration: 0.35, stagger: 0.1 },
        );
  },
  methods: {
    goToRoute(name) {
      // debugger
      this.$router.push({
        name,
      });

      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
