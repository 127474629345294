import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';

export default class JsonField extends GenericField {
  forPresenter(value) {
    return value;
  }

  forFormInitialValue(value) {
    return value;
  }
  
  forFilterInitialValue(value) {
    return value;
  }

  forFilterRules() {
    return undefined;
  }

  forFormRules() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forFormCast() {
    let yupChain = yup
      .object()
      .nullable(true)
      .label(this.label);

    return yupChain;
  }

  forFilterCast() {
    return yup
      .object()
      .nullable(true)
      .label(this.label);
  }
  
  forFilter() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forExport() {
    let yupChain = yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        return JSON.stringify(originalValue, null, 2);
      });
    return yupChain;
  }

  forImport() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }
}
