import { render, staticRenderFns } from "./news-card.vue?vue&type=template&id=ccd2e642&scoped=true&"
import script from "./news-card.vue?vue&type=script&lang=js&"
export * from "./news-card.vue?vue&type=script&lang=js&"
import style0 from "./news-card.vue?vue&type=style&index=0&id=ccd2e642&prod&lang=scss&scoped=true&"
import style1 from "./news-card.vue?vue&type=style&index=1&id=ccd2e642&prod&lang=scss&scoped=true&"
import style2 from "./news-card.vue?vue&type=style&index=2&id=ccd2e642&prod&lang=scss&"
import style3 from "./news-card.vue?vue&type=style&index=3&id=ccd2e642&prod&lang=scss&scoped=true&"
import style4 from "./news-card.vue?vue&type=style&index=4&id=ccd2e642&prod&lang=scss&scoped=true&"
import style5 from "./news-card.vue?vue&type=style&index=5&id=ccd2e642&prod&lang=scss&scoped=true&"
import style6 from "./news-card.vue?vue&type=style&index=6&id=ccd2e642&prod&lang=scss&scoped=true&"
import style7 from "./news-card.vue?vue&type=style&index=7&id=ccd2e642&prod&lang=scss&scoped=true&"
import style8 from "./news-card.vue?vue&type=style&index=8&id=ccd2e642&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccd2e642",
  null
  
)

export default component.exports
import {QCard,QImg,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QImg,QIcon})
