import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-activity';

export class ActivityService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query ACTIVITY_LIST(
          $filter: ActivityFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          activityList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              logo
              name {
                en
                ar
              }
              details {
                title {
                  en
                  ar
                }
                subject {
                  en
                  ar
                }
              }

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.activityList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ACTIVITY_FIND($id: String!) {
          activityFind(id: $id) {
            id
            branchId
            logo
            name {
              en
              ar
            }
            details {
              title {
                en
                ar
              }
              subject {
                en
                ar
              }
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.activityFind;
  }
}
