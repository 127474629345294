import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import { SettingsService } from '@/modules/settings/settings-service';
import DarkModeButton from '@/shared/components/dark-mode-button';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Loading from '@/shared/components/loading-spinner';
import paragraphSkeleton from '@/shared/components/paragraph-skeleton';
import cardsSkeleton from '@/shared/components/cards-skeleton';
import defaultCard from '@/shared/components/default-card';
import defaultGrid from '@/shared/components/default-grid';
import ActivityCard from '@/shared/components/activity-card';

import noContent from '@/shared/components/noContent';

import customeTitle from '@/shared/components/title';
import pagination from '@/shared/components/pagination';

import Banner from '@/shared/components/banner';
import PortalVue from 'portal-vue';
import './quasar';
import AsyncComputed from 'vue-async-computed';
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css'; //Vuesax styles
// import { i18n } from '@/i18n';
import i18n from './vueI18n';

// Custom Styles
import './scss/index.scss';
// import stylus from "stylus"
// Vue.use(stylus)
// import './scss/first-team.styl'

import vueVimeoPlayer from 'vue-vimeo-player';
import CKEditor from 'ckeditor4-vue';
import VueAos from 'vue-aos';
Vue.use(VueAos);

Vue.use(CKEditor);

// import { Hooper, Slide } from 'hooper';
// import 'hooper/dist/hooper.css';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSocialSharing from 'vue-social-sharing';

// import the library
import VueNavigationBar from 'vue-navigation-bar';

// css import for when you want to import the component css into your css file/files
// import "/path/to/node_modules/vue-navigation-bar.css";
// javascript import for when you're importing the css directly in your javascript
import 'vue-navigation-bar/dist/vue-navigation-bar.css';

// import VueYoutube from 'vue-youtube'
// Vue.use(VueYoutube)
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// or
// import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// or
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

Vue.use(VueSplide);

import VideoBg from 'vue-videobg';
import VideoPlayer from 'vue-videojs7';
Vue.use(VideoPlayer);
Vue.component('video-bg', VideoBg);
Vue.component('dark-mode-button', DarkModeButton);
Vue.component('loading', Loading);
Vue.component('banner', Banner);
Vue.component('paragraph-skeleton', paragraphSkeleton);
Vue.component('cards-skeleton', cardsSkeleton);
Vue.component('default-card', defaultCard);
Vue.component('default-grid', defaultGrid);
Vue.component('activity-card', ActivityCard);
Vue.component('custome-title', customeTitle);
Vue.component('pagination', pagination);
Vue.component('no-content', noContent);
import VueGlide from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';

Vue.use(VueGlide);
Vue.component('vue-navigation-bar', VueNavigationBar);
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component(
  'vue-phone-number-input',
  VuePhoneNumberInput,
);

import AOS from 'aos';
import 'aos/dist/aos.css';
// import { DockMenu } from "vue-dock-menu";
// import "vue-dock-menu/dist/vue-dock-menu.css";
// Vue.use(DockMenu);

// import Gallery from "vue-cover-gallery";
// Vue.use(Gallery);

// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

(async function () {
  document.title = i18n.t('app.title');
  ProgressBar.start();
  SettingsService.fetchAndApply();

  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip =
    process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // ======================================= New Installer ======================================= //
  //#region [ New Installer ]
  Vue.use(Vuesax);
  Vue.use(AsyncComputed);
  Vue.use(VueSocialSharing);
  // Make BootstrapVue available throughout your project
  Vue.use(BootstrapVue);
  // Optionally install the BootstrapVue icon components plugin
  Vue.use(IconsPlugin);
  Vue.use(vueVimeoPlayer);
  //#endregion

  // ============================================================================================= //

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app),
    mounted() {
      AOS.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 100,
      });
    },
  }).$mount('#app');
})();
