const Layout = () =>
  import('@/modules/layout/components/layout.vue');
import { i18n } from '@/vueI18n';

const LibraryViewPage = () =>
  import(
    '@/modules/library/components/library-view-page.vue'
  );

export default [
  {
    // path: '',
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'library',
        path: 'library',
        component: LibraryViewPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.library');
          next();
        },
      },
    ],
  },
];
