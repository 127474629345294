
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-footer',

  data() {
    return {
      title: 'Smouha SC',
      description:
        'Smouha Sports and Social Club, one of the oldest Egyptian clubs in Alexandria Governorate, where the club was established in 1949',
      contact: 'Contact us on',
    };
  },
  computed: {
    ...mapGetters({
      // currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      socials: 'layout/socials',
      branches: 'branch/view/rows',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    lang() {
      return this.$i18n.locale;
    },
    mobileBreakpoint() {
      return this.is_screen_sm || this.is_screen_xs;
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doFetchSocials: 'layout/doFetchSocials',
    }),

    i18n(key, args) {
      return i18n(key, args);
    },
  },
  async created() {
    await this.doFetchSocials();
    console.log('this is socials', this.socials);
  },
};
