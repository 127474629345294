import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=5b7a9bc2&scoped=true&"
import script from "./menu.vue?vue&type=script&lang=js&"
export * from "./menu.vue?vue&type=script&lang=js&"
import style0 from "./menu.vue?vue&type=style&index=0&id=5b7a9bc2&prod&lang=scss&scoped=true&"
import style1 from "./menu.vue?vue&type=style&index=1&id=5b7a9bc2&prod&lang=scss&"
import style2 from "./menu.vue?vue&type=style&index=2&id=5b7a9bc2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b7a9bc2",
  null
  
)

export default component.exports
import {QMenu,QItem,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMenu,QItem,QIcon})
