import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-branch';

export class BranchService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query BRANCHT_LIST(
          $filter: BranchFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          branchList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              address {
                en
                ar
              }
              images
              backgroundVideo
              thumbnail

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: null,
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.branchList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND_BRANCH($id: String!) {
          branchFind(id: $id) {
            description {
              en
              ar
            }
            id
            backgroundVideo
            images
            name {
              en
              ar
            }
            thumbnail
          }
        }
      `,
      variables: {
        id,
      },
    });
    return response.data.branchFind;
  }
}
