import Layout from '@/modules/layout/components/layout.vue';
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const NewsListPage = () =>
  import('@/modules/news/components/news-list-page.vue');
const NewsViewPage = () =>
  import('@/modules/news/components/news-view-page.vue');
const FirstTeamTest = () =>
  import('@/modules/news/components/first-team-test.vue');
export default [{
  path: '/:lang?',
  // path: '',
  exact: true,
  component: Layout,
  children: [
    {
      name: 'news',
      path: 'news',
      component: NewsListPage,
      meta: { auth: false },
      beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('menu.news') 
        next();
      },
    },
    {
      name: 'news-view',
      path: 'news/:id',
      component: NewsViewPage,
    },
    {
      name: 'first-team',
      path: '/first-team',
      component: FirstTeamTest,
    },

  ]
}];