export default {
  namespaced: true,
  state: {
    current: 'smouha',
  },

  getters: {
    current: (state) => state.current,
  },

  mutations: {
    BRANCH_CHANGED(state, payload) {
      state.table = payload;
    },
  },

  actions: {
    changeBranch({ commit }, value) {
      commit('BRANCH_CHANGED', value);
      console.log('logged form test store');
    },
  },
};
