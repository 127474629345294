import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-library';

export class libraryServices {
  static async list(branchId) {
    const response = await graphqlClient.query({
      query: gql`
        query LIBRARY_LIST(
          $filter: LibraryFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          libraryList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              location {
                en
                ar
              }
              phoneNumber
              openHours {
                en
                ar
              }
              images
              banner
              branchId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 10,
          sortBy: 'asc',
        },
      },
    });
    return response.data.libraryList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query LIBRARY_FIND($id: String!) {
          libraryFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            location {
              en
              ar
            }
            phoneNumber
            openHours {
              en
              ar
            }
            images
            banner

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
      },
    });
    return response.data.libraryFind;
  }
}
