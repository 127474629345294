import { render, staticRenderFns } from "./sponsor.vue?vue&type=template&id=9395b82a&scoped=true&"
import script from "./sponsor.vue?vue&type=script&lang=js&"
export * from "./sponsor.vue?vue&type=script&lang=js&"
import style0 from "./sponsor.vue?vue&type=style&index=0&id=9395b82a&prod&lang=scss&scoped=true&"
import style1 from "./sponsor.vue?vue&type=style&index=1&id=9395b82a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9395b82a",
  null
  
)

export default component.exports
import {QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn})
