import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const telecomListPage = () =>
  import(
    '@/modules/telecom/components/telecom-list-page.vue'
  );

const telecomViewPage = () =>
  import(
    '@/modules/telecom/components/telecom-view-page.vue'
  );
export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'telecom',
        path: 'telecom',
        component: telecomListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.telecoms');
          next();
        },
      },
      {
        name: 'telecom-view',
        path: 'telecom/:id',
        component: telecomViewPage,
      },
    ],
  },
];
