import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-restaurant';

export class RestaurantService {
  static async list(branchId, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query RESTAURANT_LIST(
          $filter: RestaurantFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          restaurantList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              location {
                en
                ar
              }
              phoneNumber
              openHours {
                en
                ar
              }
              logo_thumbnail
              logo
              banner
              menus

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter: { branchId: branchId },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.restaurantList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query RESTAURANT_FIND($id: String!) {
          restaurantFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            location {
              en
              ar
            }
            phoneNumber
            openHours {
              en
              ar
            }
            logo_thumbnail
            logo
            banner
            menus

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return response.data.restaurantFind;
  }
}
