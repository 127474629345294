
import VuePhoneNumberInput from 'vue-phone-number-input';
import PincodeInput from 'vue-pincode-input';
import i18n from '@/vueI18n';

export default {
  components: {
    VuePhoneNumberInput,
    PincodeInput,
  },
  props: ['loginModal'],
  name: 'app-login-modal',
  data() {
    return {
      memberId: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      phoneCode: '',
      step: 1,
      loginWithEmail: false,
      VuePhoneEnglish: {
        countrySelectorLabel: 'Country Code',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      VuePhoneArabic: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'إختر بلد',
        phoneNumberLabel: 'رقم الهاتف',
        example: 'مثال :',
      },
    };
  },
  computed: {
    showModal() {
      return this.loginModal;
    },
    language() {
      return i18n.locale;
    },
  },
  methods: {
    closeModal() {
      this.step = 1;
      this.$emit('close');
    },
    onSubmit() {
      if (this.step == 6) {
        return this.closeModal();
      }
      this.step++;
    },
    chooseUser() {
      this.step++;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
