
export default {
  data() {
    return {
      isSubMenuActive: false,
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },

    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
    goToRoute(name) {
      // debugger
      this.$router.push({
        name,
      });
      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  props: ['values'],
};
