import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';
import firebase from 'firebase/app'
import 'firebase/firestore'
// import AuthService from '@/modules/auth/auth-service';
import moment from 'moment';

export class SettingsService {
  static fetchAndApply() {
    // let settings = null;
    // try {
    //   
    //   settings = await this.find();
    // } catch (error) {
    //   AuthService.signout();
    //   throw error;
    // }
    let settings = { theme: 'astronaut-blue' };
    this.applyTheme(settings.theme);
  }
  static async update(id,values){
    const date = moment().format('DD/MM/YYYY')
    const time = moment().format('hh:mm a')
    await firebase.firestore().collection('settings').doc(id).update({date,time,...values})
  }
  static async delete(id){
    await firebase.firestore().collection('settings').doc(id).delete()
  }
  static async find() {
    const response = await graphqlClient.query({
      query: gql`
        query settingsFind {
          settingsFind {
            theme
            barcodeTitle
            pinCode
            title
            barcodeName
            signature
          }
        }
      `,
    });

    return response.data.settingsFind;
  }
  static async getOldSettings(){
    let arr = []
    let ids = (await firebase.firestore().collection('settings').get()).docs.map(I => I.id)
    ids  = ids.filter(I => I !== 'default')
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];
      const doc = (await firebase.firestore().doc(`settings/${element}`).get()).data()
      arr.push(doc)
    }
    return arr
  }
  static async save(settings) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_SAVE($settings: SettingsInput!) {
          settingsSave(settings: $settings)
        }
      `,

      variables: {
        settings,
      },
    });

    return response.data.settingsSave;
  }

  static applyTheme(color) {
    const oldLink = document.getElementById('theme-link');

    const link = document.createElement('link');
    link.setAttribute('id', 'theme-link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', `${process.env.BASE_URL}theme/${color}.css`);

    if (oldLink) {
      document
        .getElementsByTagName('head')
        .item(0)
        .replaceChild(oldLink, link);
    } else {
      document
        .getElementsByTagName('head')
        .item(0)
        .appendChild(link);
    }
  }
}
