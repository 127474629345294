import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const NonAthletesListPage = () =>
  import(
    '@/modules/non-athletes/components/non-athletes-list-page.vue'
  );
const NonAthletesViewPage = () =>
  import(
    '@/modules/non-athletes/components/non-athletes-view-page.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'non-athletes',
        path: 'non-athletes',
        component: NonAthletesListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.non-athletes');
          next();
        },
      },
      {
        name: 'non-athletes-view',
        path: 'non-athletes/:id',
        component: NonAthletesViewPage,
      },
    ],
  },
];
