
// eslint-disabled
import { mapGetters } from 'vuex';

import moment from 'moment';
import { NewsModel } from '@/modules/news/news-model';

export default {
  name: 'app-news-card',
  props: ['record'],
  data() {
    return {
      likedArticle: false,
      thumbnail: null,
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    isNews() {
      if (this.$route.path.includes('news')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getThumbnailForVideo(videoUrl) {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.style.display = 'none';
      canvas.style.display = 'none';

      // Trigger video load
      await new Promise((resolve, reject) => {
        video.addEventListener('loadedmetadata', () => {
          video.width = video.videoWidth;
          video.height = video.videoHeight;
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          // Seek the video to 25%
          video.currentTime = video.duration * 0.35;
        });
        video.addEventListener('seeked', () => resolve());
        video.src = videoUrl;
      });

      // Draw the thumbnailz
      canvas
        .getContext('2d')
        .drawImage(
          video,
          0,
          0,
          video.videoWidth,
          video.videoHeight,
        );
      this.thumbnail = canvas.toDataURL('image/png');
    },
    doLikeArticle(id) {
      console.log('id', id);
      this.likedArticle = !this.likedArticle;
      // this.likedArticle = null
      // if(this.likedArticle == id){
      //   this.likedArticle = null
      // }
      // else{

      //   this.likedArticle = id
      // }
    },
    // rowPresenter(row,fieldName){
    //     if(this.isRTL){
    //         return row[fieldName]['ar']
    //     }
    //     return row[fieldName]['en']
    //   },
    presenter(row, fieldName) {
      const val = NewsModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(row, 'title_en');
        return val['en'] ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },

    // presenterMap(row, fieldName) {
    //   console.log('language in presenterMap',this.language)
    //   const val = NewsModel.presenter(row, fieldName);
    //   return val[this.language] ? val[this.language] : null
    // },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = NewsModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? new Date(date).toLocaleString(
            `${this.isRTL ? 'ar-EG' : `en-US`}`,
            {
              timeZone: 'Africa/Cairo',
            },
          )
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
  },
  async mounted() {
    this.record.videoUrl
      ? this.getThumbnailForVideo(this.record.videoUrl)
      : ``;
    console.log(this.thumbnail);
  },
};
