
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'app-menu-item',
  props: ['path', 'label', 'icon'],

  data() {
    return {
      link: '/',
      lang: localStorage.getItem('language')
    };
  },
  computed: {
    ...mapGetters({
      accountType: 'layout/accountType'
    }),
    anchor() {
      if (localStorage.getItem('language') == 'ar') {
        return "center left"
      }
      return "center right"
    }
  },

  methods: {
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }
      let routePath = null
      if (this.$route.path.includes('admin') || this.$route.path.includes('client')) {
        routePath = path.split('/')[3]
      }
      else {
        routePath = path.split('/')[2]
        if (routePath == 'drug-index') {
          routePath = 'drugIndex'
        }
      }
      const active = routePath == this.$route.name;

      return {
        active
      };
    },
    ...mapActions({
      setAccountType: "layout/setAccountType"
    }),
    setPath() {
      this.link = this.path
      // if(this.path.includes('/iam/client') || this.path.includes('/iam/admin')){
      //  let pathParams = this.path.split('/')
      //  console.log('params',pathParams)
      //  this.setAccountType(pathParams[3])
      // }
    }
  },

  created() {
  }

};
