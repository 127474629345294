import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import { firestorePlugin } from 'vuefire';
import Vue from 'vue';
import { AuctionsService } from '@/modules/auction/auction-service';
import Errors from '@/shared/error/errors';
Vue.use(firestorePlugin);

export default {
  namespaced: true,

  state: {
    loading: false,
    userLoading: false,
    record: null,
    user: null,
    viewer: null,
    viewsCounter: null,
  },

  getters: {
    record: (state) => state.record,
    viewsCounter: (state) => state.viewsCounter,
    user: (state) => state.user,
    loading: (state) => !!state.loading,
    viewer: (state) => state.viewer,
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    UPDATE_VIEWS(state, payload) {
      state.viewsCounter = payload;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ARTICLE_DETAILS_STARTED(state) {
      state.loading = true;
    },

    FIND_ARTICLE_DETAILS_SUCCESS(state) {
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_USER_STARTED(state) {
      state.user = null;
      state.userLoading = true;
    },

    FIND_USER_SUCCESS(state, payload) {
      state.user = payload;
      console.log('state user', state.user);
      state.userLoading = false;
    },

    FIND_USER_ERROR(state) {
      state.user = null;
      state.userLoading = false;
    },
  },

  actions: {
    // async doFind({ commit ,dispatch},payload) {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        console.log('ID IS ' + id);
        const response = await AuctionsService.find(id);
        console.log(response);
        commit('FIND_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    async doUpdateViews({ commit }, { id, ip }) {
      try {
        console.log('ID IS ' + id);
        const response = await AuctionsService.updateViews(
          id,
          ip,
        );
        commit('UPDATE_VIEWS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    doFetchViewers: firestoreAction(
      async (
        { bindFirestoreRef, commit, getters },
        payload,
      ) => {
        commit('FIND_ARTICLE_DETAILS_STARTED');
        const db = firebase.firestore();
        // const isLiked = db.collection(`news/Ok3BuXoP6Q2X3tsPBAGG/likes/${payload.userId}`)
        const isAViewer = db
          .collection(`auction/${payload.articleId}/views`)
          .doc(payload.userId);

        // }
        await bindFirestoreRef('viewer', isAViewer);
        if (getters.viewer === null) {
          await db
            .collection(
              `auction/${payload.articleId}/views`,
            )
            .doc(payload.userId)
            .set({ id: payload.userId });

          await firebase
            .firestore()
            .collection(`auction/`)
            .doc(payload.articleId)
            .update({
              viewersCount:
                firebase.firestore.FieldValue.increment(1),
            });
        }
        commit('FIND_ARTICLE_DETAILS_SUCCESS');
      },
    ),
  },
};
